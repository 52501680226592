import { Feather } from '@expo/vector-icons';
import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, ViewStyle, Image } from 'react-native';
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';

interface State {

}

interface Props {
  onAction: any
  style: ViewStyle
  isTransparent?: boolean

}

class ButtonBar extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  actions: any[] = [{ label: "Play", icon: "play", type: "play" }, {
    label: "Background",
    icon: "image",
    type: "image"
  }, {
    label: "Download",
    icon: "download"
  }, { label: "Share", icon: "share-2" },]

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  action(actionLabel: string) {
    this.props.onAction(actionLabel);

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[this.props.style, style.row, {
        borderTopWidth: 0,
        borderRadius: 10,
        width: '100%',
        borderColor: colors.primaryColor,
        backgroundColor: this.props.isTransparent ? "transparent" : colors.secondaryColor,
        justifyContent: 'space-around',
        alignItems: 'center'
      }]}>
        {this.actions.map((action: any, index: number) => {
          return (
            <TouchableOpacity key={index} onPress={this.action.bind(this, action.label)} style={{}}>

              <View style={[{
                justifyContent: 'center',
                padding: 5,
                width: 100,
                borderRadius: colors.borderRadius,
                alignItems: 'center',
                marginLeft: 0,
                marginRight: 0
              }]}>
                <View style={[style.row, style.hcenter, style.vcenter, {
                  backgroundColor: colors.pinkish,
                  borderRadius: 20,
                  width: 40,
                  height: 40
                }]}>
                  <Feather name={action.type === 'play' ? (this.context.state.isPlaying ? 'pause' : 'play') : action.icon} size={22} color={colors.greaysh} />
                </View>

                <Text style={{
                  color: colors.neutralBlue,
                  marginTop: 5,
                  fontSize: 11,

                }}>{action.type === 'play' ? (this.context.state.isPlaying ? 'Pause' : 'Play') : action.label}</Text>
              </View>
            </TouchableOpacity>
          )
        })}

      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',

  }
});

export default ButtonBar;
