import { FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    TextInput,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    ScrollView,
    ImageBackground,
    KeyboardAvoidingView,
    Platform
} from 'react-native';
import {
    createUserMessageThreads,
    updateUserMessageThreads,
    createUserMessage,
    createLyriNotification,
    deleteLyriNotification
} from "./../graphql/mutations";
import colors from '../constants/colors';
import { Buffer } from 'buffer';
import style from '../constants/style';
import { AppContext, IContext } from "../store/app-provider";
import * as queries from "../graphql/queries";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { UserMessageThreads } from '../models';
import ItemsList from '../components/items-list';
import UserButton from '../components/user-button';
import {
    onCreateUserMessage
} from "./../graphql/subscriptions";
import navigationService from '../services/navigation-service';
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import DaysPassed from '../components/days-passed';
import { SheetManager } from 'react-native-actions-sheet';
interface Props {

}

interface State {
    isBusy?: boolean,
    message?: string
    thread?: UserMessageThreads
}

class MessageScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    itemList: any
    savePhotoKey: string = ""
    messageSubscription: any
    constructor(props: any) {
        super(props);
        this.state = {
            isBusy: false,
            message: ""
        }
    }

    async componentDidMount() {
        if (!this.context.state.selectedUser || !this.context.state.user) {
            return;
        }
        await this.checkThread();
        this.messageSubscription = API.graphql(graphqlOperation(onCreateUserMessage, { filter: { and: [{ recipientId: { eq: this.context.state.user?.id } }, { senderId: { eq: this.context.state.selectedUser?.id } }] } })).subscribe({
            next: async (messageData: any) => {
                console.log("messageData", messageData.value.data.onCreateUserMessage);
                const message = messageData.value.data.onCreateUserMessage;
                await this.setMessageImage(message);
                let messages = this.context.state.messageList;
                const messageItems = [...messages.items];
                messageItems.push(message);
                messages.items = messageItems;
                this.context.setAppProp({ messageList: messages });
                if (message.notificationId) {
                    API.graphql(graphqlOperation(deleteLyriNotification, { input: { id: message.notificationId } }))
                }

            }
        });
    }
    componentWillUnmount() {
        this.messageSubscription.unsubscribe();
    }
    async setMessageImage(message: any) {
        if (message.imageKey && !message.imageUrl) {
            message.imageUrl = await Storage.get(message.imageKey, { level: 'public' });
        }
    }

    async getMessages(token?: string) {
        let messages = (await API.graphql({
            query: queries.getMessagesByThread,
            variables: { threadId: this.state.thread?.id, limit: 8, sortDirection: 'DESC', nextToken: token },
        }) as any).data.getMessagesByThread
        messages.items = messages.items.filter((item: any) => item.sender && item.recipient);
        messages.items.forEach(async (message: any) => {
            await this.setMessageImage(message);
        })
        return { items: messages.items, nextToken: messages.nextToken };
    }
    async loadPhoto() {
        const result = await navigationService.props.launchImageLibrary({
            maxWidth: 600,
            maxHeight: 1100,
            mediaType: 'photo', cameraType: 'back'
        });
        //save the resulting image to s3
        if (!result.didCancel) {

            const photo = await navigationService.props.RNFS.readFile(result.assets?.[0].uri || "", 'base64')
            const photoBlob = Buffer.from(photo, 'base64');
            const photoExtension = result.assets?.[0].uri?.split('.').pop();
            const key = `${uuidv4()}.${photoExtension}`
            this.savePhotoKey = key;
            const response = await Storage.put(key, photoBlob, {
                contentType: result.assets?.[0].type,
                level: 'public',
                progressCallback: (progress: any) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
            });
            //const savePhotoUrl = await Storage.get(key, { level: 'public' });
            this.sendMessage(key);

        }
    }
    async sendMessage(imageKey?: string) {
        if (!this.context.state.isPremiumSubscriber && !this.context.state.isAdmin) {
            SheetManager.show('go_premium_sub');
            return;
        }
        if (!this.state.thread) {
            return;
        }
        if (!imageKey) {
            if (this.state.message?.length === 0 || !this.state.thread) {
                return;
            }
        }
        console.log("sendMessage", imageKey);
        this.setState({ isBusy: true });
        const mess = this.state.message;
        let notification;
        if ((this.context.state.selectedUser?.blockedUserIds || []).indexOf(this.context.state.user?.id || "") === -1) {
            notification = (await API.graphql(graphqlOperation(createLyriNotification, {
                input: {
                    userId: this.context.state.selectedUser?.id,
                    seen: false,
                    notificationType: "message",
                    notificationCreatedBy: this.context.state.user?.id,
                    notificationData: JSON.stringify({
                        messageToUser: this.context.state.selectedUser?.id,
                        imageKey: imageKey,
                        message: mess
                    }),
                    savedAt: new Date().toISOString()
                }
            })) as any).data.createLyriNotification;
        }

        const message = (await API.graphql(graphqlOperation(createUserMessage, {
            input: {
                notificationId: notification?.id || "",
                threadId: this.state.thread?.id,
                senderId: this.context.state.user?.id,
                recipientId: this.context.state.selectedUser?.id,
                message: this.state.message,
                imageKey: imageKey,
                savedAt: new Date().toISOString(),
            }
        })) as any).data.createUserMessage;
        let messages = this.context.state.messageList;
        const messageItems = [...messages.items];
        await this.setMessageImage(message);
        messageItems.push(message);
        messages.items = messageItems;
        this.context.setAppProp({ messageList: messages });
        this.setState({ isBusy: false, message: "" });
        API.graphql(graphqlOperation(updateUserMessageThreads, {
            input: {
                id: this.state.thread?.id,
                lastMessage: imageKey ? 'photo' : mess,
                savedAt: new Date().toISOString(),
            }
        }))

        if (this.context.state.selectedUser?.deviceToken && (this.context.state.selectedUser?.blockedUserIds || []).indexOf(this.context.state.user?.id || "") === -1) {
            if (!imageKey) {
                this.context.sendPushNotification("New message from " + this.context.state.user?.firstName, `${this.context.state.user?.firstName} messaged you: "${mess?.substr(0, 20)}..."`, { userId: this.context.state.user?.id, notificationType: "message", notId: notification.id }, this.context.state.selectedUser?.deviceToken)
            } else {
                this.context.sendPushNotification("New message from " + this.context.state.user?.firstName, `${this.context.state.user?.firstName} sent you a photo`, { userId: this.context.state.user?.id, notificationType: "message", notId: notification.id }, this.context.state.selectedUser?.deviceToken)
            }
        }

    }
    async checkThread() {
        return new Promise(async (resolve, reject) => {
            let thread;
            thread = (await API.graphql({ query: queries.getUserMessageThreads, variables: { id: this.context.state.user?.id + "_" + this.context.state.selectedUser?.id } }) as any).data.getUserMessageThreads;
            if (!thread) {
                thread = (await API.graphql({ query: queries.getUserMessageThreads, variables: { id: this.context.state.selectedUser?.id + "_" + this.context.state.user?.id } }) as any).data.getUserMessageThreads;
            }
            if (!thread) {
                thread = (await API.graphql(graphqlOperation(createUserMessageThreads, {
                    input: {
                        id: this.context.state.user?.id + "_" + this.context.state.selectedUser?.id,
                        senderId: this.context.state.user?.id,
                        recipientId: this.context.state.selectedUser?.id,
                        lastMessage: "",
                        savedAt: new Date().toISOString(),
                    }
                })) as any).data.createUserMessageThreads;
            }
            this.setState({ thread: thread }, () => {
                resolve(true);
            });
        })
    }

    render() {
        const renderItem = ({ item, index }: any) => {
            return <View key={index} style={[style.row, style.vcenter, {
                marginTop: 15, marginBottom: 10, padding: 20, backgroundColor: colors.pinkish,
                borderRadius: colors.borderRadius
            }]}>
                <UserButton hideBorder={false} user={item.sender} size={40}></UserButton>
                <View style={[style.column, { flex: 1 }]}>
                    {item.imageKey ? <TouchableOpacity style={{ width: '100%', height: 300, marginBottom: 0, marginLeft: 10, marginRight: 0 }} onPress={() => {
                        this.context.setAppProp({ 'imagePreview': item.imageUrl });
                    }}><ImageBackground source={{ uri: item.imageUrl }} imageStyle={{ borderRadius: colors.borderRadius, resizeMode: 'cover', width: '100%', height: 300 }} style={{ flex: 1, height: 300 }} >
                        </ImageBackground></TouchableOpacity> : <Text style={[style.text, { fontSize: 16, marginLeft: 10, width: '100%' }]}>{item.message}</Text>}
                    <DaysPassed style={{ marginLeft: 10, marginTop: 10 }} date={item?.savedAt}></DaysPassed>
                </View>


            </View>

        }
        return (

            <View style={style.page}>
                <View style={[style.container, { padding: 20 }]}>

                    {this.state.thread ? <ItemsList style={{ marginBottom: 80 }} ref={ref => {
                        this.itemList = ref
                    }} cache={false} disableBottomScroll={false} isTopMore={true} renderItem={renderItem} disableRefresh={false} stateKey={'messageList'} fn={this.getMessages.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.messageList}></ItemsList> : <View style={{ flex: 1, marginBottom: 80 }}>

                    </View>}

                    <View pointerEvents={!this.state.thread ? "none" : "auto"} style={[style.row, style.vcenter, style.fullWidth, { backgroundColor: "#ffffff", position: 'absolute', bottom: 10, opacity: !this.state.thread ? 0.5 : 1 }]}>


                        <TextInput style={{
                            flex: 1,
                            height: 80,
                            opacity: !this.context.state.user ? 0.5 : 1,
                            borderRadius: colors.borderRadius,
                            borderColor: colors.primaryColor,
                            borderWidth: 0,
                            fontSize: 16,
                            backgroundColor: colors.pinkish,
                            padding: 20
                        }}
                            placeholder="Message"
                            multiline={true}
                            autoCorrect={false}
                            blurOnSubmit={true}
                            maxLength={500}
                            enablesReturnKeyAutomatically={true}
                            onSubmitEditing={() => {
                                this.sendMessage()
                            }}
                            contextMenuHidden={!this.context.state.user}
                            value={this.state.message}
                            numberOfLines={2}
                            onChangeText={(text) => this.setState({ message: text })}
                        />


                        <TouchableOpacity onPress={this.loadPhoto.bind(this)} style={[style.row, style.hcenter, { position: 'absolute', right: 50, bottom: 15 }]}>
                            <View style={[style.row, style.hcenter, style.vcenter, { width: 50, height: 50, borderRadius: 25, borderWidth: 0, backgroundColor: colors.bluePrimary, borderColor: colors.bluePrimary }]}>
                                <MaterialIcons name="photo-camera" size={24} color={colors.whiteish} />
                            </View>
                        </TouchableOpacity>
                        <View style={[style.fullHeight, style.row, style.hcenter, style.vcenter, { width: 40 }]}>


                            {this.state.isBusy ?
                                <ActivityIndicator size="small" style={{ marginLeft: 10 }} color={colors.busyIndicator} /> :
                                <TouchableOpacity disabled={!this.state.thread} onPress={() => {
                                    this.sendMessage();
                                }} style={{ marginLeft: 10 }}>
                                    <Ionicons style={{}} name="ios-send-sharp" size={24} color={colors.bluePrimary} />
                                </TouchableOpacity>}
                        </View>
                    </View>
                </View>
            </View>

        )
    };

}

export default MessageScreen;

const styles = StyleSheet.create({});
