import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { SheetManager } from 'react-native-actions-sheet';
import PrimaryButton from './primary-button';
import * as Animatable from 'react-native-animatable';
import IconMenu from './icon-menu';
import AsyncStorage from "@react-native-async-storage/async-storage";
interface State {
    appMenu?: any
    blinkMenu?: boolean,
    showBlinkHoroscope?: boolean
    showBlinkNews?: boolean
    showRiddles?: boolean
}
interface Props {
    style?: ViewStyle
}
const fadeInOut = {
    0: {
        opacity: 0.5
    },
    0.5: {
        opacity: 1
    },
    1: {
        opacity: 0.5
    }
};
interface ItemType {
    label: string,
    icon: string,
    visibleKey: string,
    type?: string
}
class AppMenu extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        this.state = {
            blinkMenu: false,
            showBlinkHoroscope: false,
            showBlinkNews: false,
            appMenu: [
                {
                    label: "Riddles",
                    icon: <MaterialCommunityIcons name={"head-question-outline"} size={24} color="black" />
                },
                {
                    label: "Horoscope",
                    icon: <FontAwesome5 name={"star"} size={24} color="black" />
                },
                {
                    label: "News",
                    icon: <MaterialCommunityIcons name={"lightning-bolt"} size={24} color="black" />
                },
                {
                    label: "Stats",
                    icon: <MaterialCommunityIcons name={"google-analytics"} size={24} color="black" />
                },
                {
                    label: "Buy Premium",
                    icon: <FontAwesome5 name={"shopping-cart"} size={24} color="black" />
                },
                {
                    label: "Terms and Conditions",
                    icon: <FontAwesome5 name={"file-alt"} size={24} color="black" />

                }, {
                    label: "Privacy Policy",
                    icon: <FontAwesome5 name={"file-alt"} size={24} color="black" />
                }]

        }

    }
    itemPress = async (item: any) => {
        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
        //await SheetManager.hide("menu_sheet");
        switch (item.label) {
            case "News":
                if (Platform.OS === "web" && !this.context.state.isAdmin) {
                    setTimeout(() => {
                        SheetManager.show('download_sheet_home');
                    }, 500)
                    return;
                }
                if (Platform.OS !== "web") {
                    this.context.setScreen('News', true);
                }
                if (Platform.OS !== "web") {
                    AsyncStorage.setItem('hasSeenNews', "true");
                }
                this.checkMenusItems();
                break;
            case "Stats":
                this.context.setScreen('Top', true);
                break;
            case "Horoscope":
                if (Platform.OS === "web" && !this.context.state.isAdmin) {

                    setTimeout(() => {
                        SheetManager.show('download_sheet_home');
                    }, 500)
                    return;
                }
                if (Platform.OS !== "web") {
                    this.context.setScreen('Horoscope', true);
                }
                if (Platform.OS !== "web") {
                    AsyncStorage.setItem('hasSeenHoroscope', "true");
                }
                this.checkMenusItems();
                break;
            case "Riddles":
                if (Platform.OS === "web" && !this.context.state.isAdmin) {
                    SheetManager.show('download_sheet_home');
                    return;
                }
                if (Platform.OS !== "web") {
                    this.context.setScreen('Riddles', true);
                }
                if (Platform.OS !== "web") {
                    AsyncStorage.setItem('hasSeenRiddles', "true");
                }
                this.checkMenusItems();
                break;
            case "Promoters":
                this.context.setScreen('Promoters', true);
                break;
            case "Terms and Conditions":

                this.context.setScreen('Terms', true);
                break;
            case "Privacy Policy":

                this.context.setScreen('Privacy', true);
                break;
            case 'Buy Premium':
                if (Platform.OS === "web" && !this.context.state.isAdmin) {
                    SheetManager.show('download_sheet_home');
                    return;
                }

                setTimeout(() => {
                    SheetManager.show('buy_premium');
                }, 500)

                break
            case 'Premium Subscribe':

                setTimeout(() => {
                    SheetManager.show('buy_premium_subscribe');
                }, 500)

                break
            case 'My Liked':
                this.context.setScreen('MyLiked', true);
                break
            case 'My Posts':
                this.context.setScreen('MyPosts', true);
                break
            case 'My Account':
                this.context.setScreen('MyAccount', true);
                break
            case "Win Premium":
                SheetManager.show('go_campaign');
                break
            case 'Feedback':
                setTimeout(() => {
                    SheetManager.show('feedback_sheet');
                }, 500)
                break

        }
    }
    async checkMenusItems() {
        if (Platform.OS === 'web') return;
        const [hasSeenNews, hasSeenHoroscope, hasSeenRiddles] = await Promise.all([AsyncStorage.getItem('hasSeenNews'), AsyncStorage.getItem('hasSeenHoroscope'), AsyncStorage.getItem('hasSeenRiddles')]);
        if (!hasSeenNews) {
            this.setState({ blinkMenu: true, showBlinkNews: true })
        }
        if (!hasSeenHoroscope) {
            this.setState({ blinkMenu: true, showBlinkHoroscope: true })
        }
        if (!hasSeenRiddles) {
            this.setState({ blinkMenu: true, showRiddles: true })
        }
    }
    async componentDidMount() {
        this.checkMenusItems();
        const winPremium = {
            label: "Win Premium",
            icon: <FontAwesome5 name={"rocket"} size={24} color="black" />
        }

        if ((Platform.OS !== "web" || this.context.state.isAdmin) && this.context.state.appConfig?.showPromoters) {
            //insert first item
            this.state.appMenu.splice(0, 0, {
                label: "Promoters",
                icon: <FontAwesome5 name={"user-astronaut"} size={24} color="black" />,
                screen: "Promoters"
            })

        }
        //if (Platform.OS !== "web" && !this.context.state.isPremium && !this.context.state.user?.isOnCampaign) {
        if (!this.context.state.isPremium && !this.context.state.user?.isOnCampaign) {
            //insert first item
            this.state.appMenu.splice(0, 0, winPremium)
        }

        if (this.context.state.user) {
            let userMenu = [
                {
                    label: "Feedback",
                    icon: <FontAwesome5 name={"inbox"} size={24} color="black" />
                }]
            if (this.context.state.isAdmin) {
                userMenu.push({
                    label: "Admin",
                    icon: <FontAwesome5 name={"cog"} size={24} color="black" />
                })
            }
            //add this menu at the statrt of the array
            this.setState({ appMenu: [...userMenu, ...this.state.appMenu] })
        } else {
            this.setState({ appMenu: [...this.state.appMenu] })
        }

    }


    render() {

        return (
            <View style={[this.props.style, style.column, { backgroundColor: "#ffffff", paddingTop: 20, paddingLeft: 10, paddingRight: 10 }]}>
                {/*  {this.state.appMenu.map((item: ItemType, index) => {
                    return <TouchableOpacity key={index} onPress={() => {
                        this.itemPress(item.label)
                    }}>
                        <Animatable.View animation={this.state[item.visibleKey] ? fadeInOut : undefined} style={[style.row, style.fullWidth, style.vcenter, { padding: 10 }]} duration={1000} iterationCount="infinite">

                            <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.pinkish }]}>
                                {item.type === "material" ? <MaterialCommunityIcons name={item.icon} size={18} color={colors.greaysh} /> : <FontAwesome5 name={item.icon} size={18} color={colors.greaysh} />}
                            </View>
                            <Text style={[{ marginLeft: 15, color: colors.neutralBlue, fontFamily: 'Jost', fontSize: 16 }]}>{item.label}</Text>
                        </Animatable.View>
                    </TouchableOpacity>
                })} */}
                <IconMenu data={this.state.appMenu} style={{ width: '100%' }} onPress={(item: any) => {
                    this.itemPress(item)
                }
                }></IconMenu>
                <View style={{ flex: 1 }}></View>
                {this.context.state.user ? <PrimaryButton align={'right'} onPress={() => {

                    this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false }, () => {
                        this.context.signOut();
                    })
                }} icon={
                    <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                        width: '100%',
                        marginTop: 10, marginBottom: 10
                    }} /> : <PrimaryButton align={'left'} onPress={() => {
                        this.context.setScreen("Sign In", true);
                        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
                    }} icon={
                        <FontAwesome style={{}} size={20} color={colors.iconColorWhite} name='user'></FontAwesome>} label={'Sign In'} style={{
                            width: '100%',
                            marginTop: 10, marginBottom: 10
                        }} />}
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default AppMenu;
