import * as React from 'react';
import { ActivityIndicator, Linking, Platform, StyleSheet, Switch, Text, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import AsyncStorage from "@react-native-async-storage/async-storage";
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { Ionicons } from "@expo/vector-icons";
import LyriItem from "../components/lyri-item";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import navigationService from '../services/navigation-service';

const getNext9AM = () => {
    const now = new Date();
    let next9AM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);

    // If it's already past 9 AM, set next9AM to 9 AM tomorrow
    if (now.getTime() > next9AM.getTime()) {
        next9AM.setDate(next9AM.getDate() + 1);
    }

    return next9AM;
};
interface Props {

}

interface State {
    hasEnableNotification: boolean
    isBusy?: boolean
    newsDate: string
    day: string
}

class HoroscopeScreen extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        //day is just the name of today's day like Monday, Tuesday, etc.
        const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][new Date().getDay()];

        this.state = {
            day,
            hasEnableNotification: false,
            newsDate: new Date().toISOString().split("T")[0]
        }
    }

    async componentDidMount() {
        const hasEnableNotification = (await AsyncStorage.getItem('hasEnableNotification') === "true") || false;
        this.setState({ hasEnableNotification });
    }

    async getMyPosts(token?: string) {
        const horoscopeDate = new Date().toISOString().split('T')[0].split('-').join('');
        console.log("horoscopeDate", horoscopeDate);
        let horoscopeList = (await API.graphql({
            query: queries.getHoroscopeByDate,
            variables: { horoscopeDate: horoscopeDate, limit: 24, filter: {}, sortDirection: 'ASC', nextToken: token },
        }) as any).data.getHoroscopeByDate;
        //console.log(history);
        //filter the horoscopeList.items to contain unique title
        let uniqueHoroscopeList = [];
        let uniqueHoroscopeListTitles = [];
        horoscopeList.items.forEach((item: any) => {
            if (uniqueHoroscopeListTitles.indexOf(item.title) === -1) {
                uniqueHoroscopeListTitles.push(item.title);
                uniqueHoroscopeList.push(item);
            }
        });
        horoscopeList.items = uniqueHoroscopeList;
        console.log(JSON.stringify(horoscopeList));
        return { items: horoscopeList.items, nextToken: horoscopeList.nextToken };
    }

    componentDidUpdate(prevPops: any) {

    }
    setNotification = async (e: boolean) => {
        AsyncStorage.setItem('hasEnableNotification', e ? "true" : "false");

        if (e) {
            /*  if (Platform.OS === 'android' && Platform.Version >= 31) {
                 Linking.openSettings(); // This is a simplified approach; consider guiding the user more directly if possible
             } */
            /*  try {
                 const next9AM = getNext9AM();
 
                 navigationService.props.PushNotification.localNotificationSchedule({
                     // Notification Message
                     message: "Your Daily Horoscope is ready!",
                     // Date to Schedule - e.g., tomorrow at 10:00 AM
                     date: next9AM, // 24 hours in milliseconds
                     // Repeat Type
                     repeatType: 'day',
                     // Additional Settings
                     channelId: "LyriTunesHoroscope", // ensure you create this channel on Android
                     // Add any additional data you want to handle on notification click
                     userInfo: { screen: 'Horoscope' },
                 });
             } catch (e) {
 
             } */
        } else {
            navigationService.props.PushNotification.cancelAllLocalNotifications();
        }
        this.setState({ hasEnableNotification: e });
    }

    render() {
        const renderItem = ({ item, index }: any) => {
            return <LyriItem hasRewarded={true} isMyPost={false} index={index} onPress={() => {
                this.context.logEvent('horoscope_view');
            }} item={item} />

        }
        const busyIndicator = () => {
            if (this.context.state.isBusy || this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }

        return (

            <View style={style.page}>
                <View style={[style.container]}>
                    <Text style={{ color: colors.textColor, fontFamily: 'Jost', fontSize: 18, width: '100%', textAlign: 'left', paddingLeft: 20 }}>Daily Horoscope - {this.state.day}</Text>
                    {/*   <View style={[style.row, style.vcenter, style.fullWidth, { marginTop: 10, marginBottom: 5, paddingLeft: 20 }]}>
                        <Switch
                            trackColor={{ false: colors.textColor, true: colors.textColor }}
                            thumbColor={colors.textColor}
                            ios_backgroundColor={colors.textColor}
                            onValueChange={(e) => {

                                this.setNotification(e);
                            }}
                            value={this.state.hasEnableNotification}
                        />
                        <Text style={{ color: colors.textColor, fontFamily: 'Jost', fontSize: 16, width: '100%', textAlign: 'left', paddingLeft: 5 }}>Enable daily notification</Text>
                    </View> */}
                    <ItemsList cache={false} disableBottomScroll={true} disableRefresh={false} renderItem={renderItem} stateKey={'horoscopeList'} fn={this.getMyPosts.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.horoscopeList}></ItemsList>
                    {busyIndicator()}
                </View>
            </View>
        )
    };

}

export default HoroscopeScreen;

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});
