import { registerRootComponent } from 'expo';

import AppWeb from './AppWeb';
registerRootComponent(AppWeb);

/* import AppMobile from './AppMobile';
registerRootComponent(AppMobile); */




