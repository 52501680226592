import { NavigationContainer } from "@react-navigation/native";

import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../store/app-provider";
import HomeScreen from './../screens/home';
import Toast from 'react-native-toast-notifications';
import * as Animatable from 'react-native-animatable';
import PrivacyScreen from './../screens/privacy';
import TermsScreen from './../screens/terms';
import MyAccountScreen from './../screens/my-account';
import ViewPostScreen from './../screens/view';
import NewsScreen from './../screens/news';
import ChartsScreen from './../screens/charts';
import MyPostsScreen from './../screens/my-posts';
import FullScreen from './../screens/full-screen';
import MyLikedScreen from './../screens/my-liked';
import HoroscopeScreen from './../screens/horoscope';
import FullPostScreen from './../screens/fullpost';
import WeekTopsScreen from './../screens/weektops';
import RiddlesScreen from './../screens/riddles';
import MessagesScreen from './../screens/messages';
import MessageScreen from './../screens/message';
import FastClipsScreen from './../screens/fast-clips';
import LoginScreen from './../screens/login';
import { ToastProvider } from "react-native-toast-notifications";
import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Constants from 'expo-constants';
import * as queries from "../graphql/queries";
const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, Hub, API } from "aws-amplify";
import React from "react";
import colors from "../constants/colors";
import env from "../constants/env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {

  Keyboard,
  Modal,
  Platform,
  BackHandler,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StatusBar,
  ImageBackground
} from "react-native";
import style from "../constants/style";
import { AntDesign, Entypo, FontAwesome, FontAwesome5, SimpleLineIcons } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import Share from "./share";
import Alert from "./alert";
import AppMenu from "./app-menu";
import RadioGroup from "./radio-group";
import DoubleButtonBar from "./double-button-bar";
import CommentsModal from "./comments-modal";
import ItemsList from "./items-list";
import UserButton from "./user-button";
import UserButtonLong from "./user-button-long";
import IconMenu from "./icon-menu";

const slideInLeft = {
  from: {
    transform: [{ translateX: -280 }], // Starts offscreen 100 px to the left
  },
  to: {
    transform: [{ translateX: 0 }], // Ends at its natural position
  },
};

interface State {
  isBusy?: boolean;
  feedbackMessage?: string,
  feedbackSubject?: string,
  isBusyFeedback?: boolean
  selectedLyricsTab: number;
}

interface Props {
  CreatePost: any
}

const link = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {

        path: 'privacy',
      },

      MyAccount: {
        path: 'my-account',
      },
      MyPosts: {
        path: 'my-posts',
      },
      MyLiked: {
        path: 'my-liked',
      },
      News: {
        path: 'news',
      },
      Promoters: {
        path: 'promoters',
      },
      Post: {
        path: 'post',
      },
      FullPost: {
        path: 'fullpost',
      },
      Top: {
        path: 'top',
      },
      Terms: {

        path: 'terms',
      }
      , View: {

        path: 'view',
      },
      'Sign In': {
        path: 'login',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  selectedUserMenu = [
    {
      label: "Message",
      icon: <FontAwesome5 name={"facebook-messenger"} size={24} color="black" />
    }, {
      label: "Block User",
      icon: <AntDesign name="flag" size={24} color="black" />
    }]
  myUserMenu = [
    {
      label: "Messages",
      icon: <FontAwesome5 name={"facebook-messenger"} size={24} color="black" />,
      screen: "Messages"
    },
    {
      label: "My Posts",
      icon: <FontAwesome5 name={"book"} size={24} color="black" />,
      screen: "MyPosts"
    }, {
      label: "My Liked",
      icon: <FontAwesome5 name={"heart"} size={24} color="black" />,
      screen: "MyLiked"
    }, {
      label: "My Account",
      icon: <FontAwesome5 name={"user-circle"} size={24} color="black" />,
      screen: "MyAccount"
    }]



  handler
  constructor(props: any) {
    super(props);
    this.state = {
      isBusy: false,
      selectedLyricsTab: 0
    }
    this.getUserCredentials();
    if (!this.handler) {
      this.handler = Hub.listen('auth', ({ payload: { event, data } }) => {

        switch (event) {
          case 'signIn':
            //console.log('auth state signIn', event);
            this.getUserCredentials();
            break;
          case 'signOut':

            break;
          case 'customOAuthState':

        }
      });
    }
  }
  onSelectedUserMenuPress = async (item: any) => {
    await SheetManager.hide('user_menu');
    switch (item.label) {
      case "Message":
        //this.context.setScreen('Message', true);
        await this.context.waitSomeSeconds(100);
        if (!this.context.state.user) {
          SheetManager.show('sign_sheet_misc');
          return;
        }
        if (this.context.state.isPremiumSubscriber || this.context.state.isAdmin) {
          this.context.setScreen('Message', true);
          this.context.logEvent("message_user");
        } else {

          SheetManager.show('go_premium_sub')
        }
        break
      case "Block User":
        await this.context.waitSomeSeconds(100);
        SheetManager.show('user_menu_report');
        break
    }
  }
  gotoMyScreen = async (item: any) => {
    await SheetManager.hide('user_my_menu');
    if (item.screen === "Messages") {
      await this.context.waitSomeSeconds(100);
      if (!this.context.state.user) {
        SheetManager.show('sign_sheet_misc');
        return;
      }
      if (this.context.state.isPremiumSubscriber || this.context.state.isAdmin) {
        this.context.setScreen('Messages', true);
      } else {
        SheetManager.show('go_premium_sub')
      }
    } else {
      this.context.setScreen(item.screen, true);
    }
  }
  componentDidMount() {

    const url = Linking.getInitialURL().then(url => {
      if (url) {
        const route = url.replace(/.*?:\/\//g, "");
        const routeSplit = route.split("?");
        if (routeSplit[0] === "view") {
          const id = routeSplit[1].split("=")[1];
          //navigationService.navigate("View", { id: id });
          this.context.setScreen("View", true, { id });
        }
        console.log("init route", route)
      }
    });
  }

  async getUserCredentials() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.context.setUser(user);
      //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(checkUser(user))
      console.log("Cognito has a user")
    } catch (e) {
      console.log("Cognito has no user")
      //(store.dispatch as ThunkDispatch<User, void, ActionTypes>)(checkUser({ attributes: { "email": "yonodo10@gmail.com", "sub": "920a4dd7-6212-48a8-b873-b7f1a72a2901" } }))
    }
  }

  async loadMagicText() {
    Keyboard.dismiss();
    this.setState({ isBusy: true });
    try {
      await this.context.getMagicText(false);
    } catch (e) {

    }
    this.setState({ isBusy: false });
  }
  async loadMagicSong() {
    Keyboard.dismiss();
    this.setState({ isBusy: true });
    try {
      await this.context.getMagicSong(false);
    } catch (e) {

    }
    this.setState({ isBusy: false });
  }
  signOut = () => {
    SheetManager.hide('user_menu');
    this.context.signOut();

  }
  sendFeedbackMessage = async () => {
    let result: any;
    this.setState({ isBusyFeedback: true });
    try {
      const result = (await API.post('lyritunesrest', '/rest/message/', { body: { toEmail: 'contact@canversi.com', mail: this.context.state.user?.email, subject: "Feedback LyriTunes - " + this.state.feedbackSubject, message: this.state.feedbackMessage } }))
      this.context.showToast("Feedback sent", "success");
    } catch (e) {
      //toast.show(e.response.data.message, { type: 'error' });

    }
    this.setState({ isBusyFeedback: false });
    SheetManager.hide('feedback_sheet');
  }


  agreePolicy() {
    this.context.setAppProp({ hasShownPolicy: true });
    if (Platform.OS === "web") {
      localStorage.setItem("hasShownPolicy2", "true");
    } else {
      AsyncStorage.setItem("hasShownPolicy2", "true");
    }
  }


  navigationStateChange = (state: any) => {
    console.log("nav state", state)
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, false, params);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  getYearlyLabel() {
    let label = ""
    let platform = Platform.OS.toString();
    //platform = "android"
    label = this.context.state.appConfig?.[platform + "_" + "premiumPSubPriceYearlyMonthly1"] ? `$${this.context.state.appConfig?.[platform + "_" + "premiumPSubPriceYearlyMonthly1"]}/month $${this.context.state.appConfig?.[platform + "_" + "premiumPSubPriceYearly1"]}/year auto-renewable` : `$${this.context.state.appConfig?.[platform + "_" + "premiumPSubPriceYearly1"]}/year auto-renewable`;
    return label;
  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }
  async getNotifications(token?: string) {
    let notifications = (await API.graphql({
      query: queries.getNotificationsByTime,
      variables: { userId: this.context.state.user?.id, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getNotificationsByTime
    notifications.items = notifications.items.filter((item: any) => item.notificationCreatedByUser);
    return { items: notifications.items, nextToken: notifications.nextToken };
  }
  gotoNotification = async (item: any) => {
    await SheetManager.hide('notifications_sheet');
    await this.context.waitSomeSeconds(100);
    const notificationData = JSON.parse(item.notificationData);
    notificationData.notificationType = item.notificationType;
    notificationData.notificationCreatedByUser = item.notificationCreatedByUser;
    notificationData.notId = item.id;
    await this.context.gotoNotification(notificationData);
    this.context.getUnseenNotifications();
  }
  render() {
    const notificationRenderItem = ({ item }: any) => {
      const notificationData = JSON.parse(item.notificationData);
      const getText = () => {
        let str;
        switch (item.notificationType) {
          case 'comment':
            str = `${item.notificationCreatedByUser?.firstName} commented "${notificationData.comment.substr(0, 20) + '...'}"`;
            break
          case 'comment-reply':
            str = `${item.notificationCreatedByUser?.firstName} replied to your comment "${notificationData.comment.substr(0, 20) + '...'}"`;
            break
          case 'like':
            str = `${item.notificationCreatedByUser?.firstName} liked your post`;
            break
          case 'message':
            if (!notificationData.imageKey) {
              str = `${item.notificationCreatedByUser?.firstName} messaged you "${notificationData.message.substr(0, 20) + '...'}"`
            } else {
              str = `${item.notificationCreatedByUser?.firstName} sent you a photo`
            }
            break
        }
        return str;
      }
      return <TouchableOpacity onPress={() => {
        this.gotoNotification(item)
      }}><View style={[style.row, {
        marginTop: 10, marginBottom: 10, padding: 20, backgroundColor: colors.pinkish, alignItems: 'center',
        borderRadius: colors.borderRadius
      }]}>
          {item.notificationCreatedByUser ? <UserButton hideBorder={false} user={item.notificationCreatedByUser} size={25}></UserButton> : null}
          <Text style={{
            color: colors.textColor,
            marginTop: 2,
            marginLeft: 10,
            fontWeight: item.seen ? 'normal' : 'bold',
            fontFamily: 'Jost',
            fontSize: 16
          }}>{getText()}</Text>
        </View></TouchableOpacity>

    }
    return (

      <NavigationContainer linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        navigationService.setTopLevelNavigator(navigatorRef);
      }}>

        <Stack.Navigator screenOptions={{
          animationDuration: 500,
          animation: 'slide_from_bottom',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "LyriTunes", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Sign In"
            component={LoginScreen}
            options={{ headerTitleAlign: 'center', title: "Sign In", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Message"
            component={MessageScreen}
            options={{ headerTitleAlign: 'center', title: "Message", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Messages"
            component={MessagesScreen}
            options={{ headerTitleAlign: 'center', title: "Messages", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={{ headerTitleAlign: 'center', title: "My Account", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyPosts"
            component={MyPostsScreen}
            options={{ headerTitleAlign: 'center', title: "My Posts", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="MyLiked"
            component={MyLikedScreen}
            options={{ headerTitleAlign: 'center', title: "My Likes", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="WeekTops"
            component={WeekTopsScreen}
            options={{ headerTitleAlign: 'center', title: "Week Tops", header: (props) => <Header {...props} /> }}
          />
          <
            Stack.Screen
            name="Horoscope"
            component={HoroscopeScreen}
            options={{ headerTitleAlign: 'center', title: "Horoscope", header: (props) => <Header {...props} /> }}
          />
          <
            Stack.Screen
            name="Riddles"
            component={RiddlesScreen}
            options={{ headerTitleAlign: 'center', title: "Riddles", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="View"
            component={ViewPostScreen}
            options={{ headerTitleAlign: 'center', title: "View", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Promoters"
            component={FastClipsScreen}
            options={{ headerTitleAlign: 'center', title: "Promoters", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="News"
            component={NewsScreen}
            options={{ headerTitleAlign: 'center', title: "News", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Top"
            component={ChartsScreen}
            options={{ headerTitleAlign: 'center', title: "Top", header: (props) => <Header {...props} /> }}
          />
          {this.props.CreatePost ? <Stack.Screen
            name="Post"
            component={this.props.CreatePost}
            options={{ headerTitleAlign: 'center', title: "Post", header: (props) => <Header {...props} /> }}
          /> : null}
          <Stack.Screen
            name="FullPost"
            component={FullPostScreen}
            options={{ headerTitleAlign: 'center', title: "Full Post", header: (props) => <Header {...props} /> }}
          />


          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Privacy Policy", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Terms and Conditions",
              header: (props) => <Header {...props} />
            }}
          />

        </Stack.Navigator>
        <Toast ref={(ref: any) => global['toast'] = ref} />
        {this.context.state.viewPost ? <View pointerEvents="auto" ref={ref => navigationService.props.viewPostRef = ref} style={{ position: 'absolute', height: '100%', width: '100%', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }} >{this.context.state.viewPost ? <ViewPostScreen style={{ width: '100%', height: '100%' }} post={this.context.state.viewPost} /> : null}</View> : null}
        {this.context.state.appMenuOpenedBack ? <TouchableOpacity onPress={() => {

          this.context.hideMenu();

        }} style={[style.fullSize, { position: 'absolute', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0, backgroundColor: 'rgba(0,0,0,0.3)' }]}>

        </TouchableOpacity> : null}
        {this.context.state.appMenuOpened ? <Animatable.View duration={300} ref={ref => navigationService.props.menuRef = ref} style={{ position: 'absolute', height: '100%', width: 280, top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }} animation={slideInLeft}>{this.context.state.appMenuOpened ? <AppMenu style={{ width: '100%', height: '100%' }} /> : null}</Animatable.View> : null}
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.setScreen('Sign In', true);
                SheetManager.hide('sign_sheet_home')
              }} message={"You need to be signed in to be able to create a new lyrics!"} acceptLabel={"Sign In"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_sheet_misc">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.setScreen('Sign In', true);
                SheetManager.hide('sign_sheet_misc')
              }} message={"You need to be signed in to access this feature!"} acceptLabel={"Sign In"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="delete_post">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="trash" size={20} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.deletePost(this.context.state.toDeletePost)
                SheetManager.hide('delete_post')
              }} message={"Are you sure you want to delete this post?"} acceptLabel={"Delete"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="sign_campaign_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={
              <FontAwesome5 name="user" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.setScreen('Sign In', true);
                SheetManager.hide('sign_campaign_sheet_home')
              }} message={"You need to be signed in to start a new campaign!"} acceptLabel={"Sign In"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_campaign">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cancel')} onCancel={async () => {
              SheetManager.hide('go_campaign');
              //AsyncStorage.setItem("neverShowCampaign", "true");
            }} onAccept={async () => {
              if (this.context.state.user) {
                SheetManager.hide('go_campaign');
                this.context.startViewCampaign();
              } else {
                await SheetManager.hide('go_campaign');
                setTimeout(() => {
                  SheetManager.show('sign_campaign_sheet_home');
                }, 100);
              }
            }} message={(this.context.state.appConfig?.campaignMessage)} acceptLabel={('Start Now')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad')

              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad');
              this.context.playRewardedAdAndGenerate();

            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_premium_sub">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cancel')} onCancel={async () => {
              SheetManager.hide('go_premium_sub')
            }} onAccept={async () => {
              await SheetManager.hide('go_premium_sub')
              await this.context.waitSomeSeconds(100);
              SheetManager.show('buy_premium')
            }} message={('This feature is available only for premium subscribers')} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad_view">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad_view')

              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad_view');
              this.context.playRewardedAndGo();
            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 10 }]}>
            <ImageBackground source={{ uri: "" }} imageStyle={{
              borderRadius: 16
            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 10, paddingBottom: 0 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 12,
                marginBottom: 5,
                fontFamily: 'Jost'
              }}>Basic package - Purchased for life, non subscription, charged immediately, removes all ads from the app. No access to premium features or full song creation</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremium();
                }, 100);
              }} adiacentMessage={`${this.context.state.products?.find(prod => prod.vendorProductId === "com.lyritunes.premium")?.price?.localizedString || "$" + this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPrice1"]}/life non-renewable`} label="Basic - No ads"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: "" }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 10, marginTop: 0, paddingBottom: 0 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 12,
                marginBottom: 5,
                fontFamily: 'Jost'
              }}>Monthly Premium Subscription - Monthly auto-renewable subscription, no trial, charged immediately and cancellable at any time, removes all ads from the app and has access to all premium features except full song creation</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);
              }} adiacentMessage={`${this.context.state.products?.find(prod => prod.vendorProductId === "com.lyritunes.premiumsub")?.price?.localizedString || "$" + this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPrice1"]}/month auto-renewable`} label="Subscribe"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: "" }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 10, marginTop: 0, paddingBottom: 0 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 12,
                marginBottom: 5,
                fontFamily: 'Jost'
              }}>Monthly Platinum Subscription - Monthly auto-renewable subscription, no trial, charged immediately and cancellable at any time, removes all ads from the app and has access to all premium features + full song creation</Text>
              <PrimaryButton isGold={true} onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false, true);
                }, 100);
              }} adiacentMessage={`${this.context.state.products?.find(prod => prod.vendorProductId === "com.lyritunes.platinum")?.price?.localizedString || "$" + this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPrice2"]}/month auto-renewable`} label="Subscribe"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: "" }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 10, marginTop: 0, paddingBottom: 0 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 12,
                marginBottom: 5,
                fontFamily: 'Jost'
              }}>Yearly Premium Subscription - Yearly auto-renewable subscription, no trial, charged immediately and cancellable at any time, removes all ads from the app and has access to all premium features except full song creation</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(true);
                }, 100);
              }} adiacentMessage={`${this.context.state.products?.find(prod => prod.vendorProductId === "com.lyritunes.premiumsubyearly")?.price?.localizedString || "$" + this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumPSubPriceYearly1"]}/year auto-renewable`} label="Subscribe"></PrimaryButton>
            </ImageBackground>
            <ImageBackground source={{ uri: "" }} imageStyle={{
              borderRadius: 16

            }} resizeMethod={'resize'} style={[style.fullWidth, style.column, style.hcenter, { padding: 10, marginTop: 0, paddingBottom: 0 }]}>
              {Platform.OS === 'android' ? <Text style={{ marginTop: 5, fontSize: 12 }}>
                Tap bellow to manage or cancel your subscription
              </Text> : null}
              {Platform.OS === 'android' ? <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  //navigate to this url https://play.google.com/store/account/subscriptions
                  Linking.openURL('https://play.google.com/store/account/subscriptions');
                }, 100);
              }} label="Manage/Cancel Subscription" style={{ marginTop: 10, width: '100%' }} isSecondary={true}></PrimaryButton> : null}
              <Text style={{ marginTop: 5, fontSize: 12 }}>
                Tap bellow to restore a previous purchased package
              </Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.restorePurchase();
                }, 100);
              }} label="Restore Purchase" style={{ marginTop: 10, width: '100%' }} isSecondary={true}></PrimaryButton>
            </ImageBackground>
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium_subscribe">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Restore')} onCancel={() => {
              this.context.restorePurchase();
              SheetManager.hide('buy_premium_subscribe');
            }} icon={
              <FontAwesome size={20} color={'#ffffff'} name='shopping-cart'></FontAwesome>} style={{ width: '100%' }} onAccept={() => {
                SheetManager.hide('buy_premium_subscribe');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);

              }} message={('Subscribe to premium and be free of ads and have access to all premium subscription features')} adiacentMessage={`$${this.context.state.appConfig?.premiumPSubPrice}/month auto-renewable`} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        {
          this.context.state.shareModalVisible ? <Modal
            animationType="fade" style={{
              display: "flex",
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          ><TouchableOpacity onPress={() => {
            this.context.setAppProp({ shareModalVisible: false })
          }} style={style.modalOverlay}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                  width: 350,
                  height: 380,
                  padding: 20,
                  backgroundColor: colors.backgroundColor,
                  borderRadius: colors.borderRadius
                }}>
                  <Share onShare={() => {
                    this.context.setAppProp({ shareModalVisible: false })
                  }}></Share>
                </View>
              </View>
            </TouchableOpacity>
          </Modal> : null
        }

        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="app_review">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert style={{ width: '100%' }} cancelLabel={"Cancel"} onCancel={async () => {
              SheetManager.hide('app_review')
            }} onAccept={async () => {
              await SheetManager.hide('app_review')
              setTimeout(() => {

                this.context.appReview();
              }, 500);
            }} message={"Hey, you! We don't have a perfect app, but we're striving every day to make it better, bug fixing and bringing new and exciting features for you to enjoy. So if overall you enjoy our humble app, please kindly give us a review, it would mean the world for us! Thank you very much!"} acceptLabel={"Rate and Review"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={Platform.OS === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColorWhite} /> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                this.context.gotoStore();
                SheetManager.hide('download_sheet_home')
              }} message={"This feature is available only in the mobile app on Google Play and AppStore!"} acceptLabel={"Download"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="user_menu_report">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={!this.context.state.isAdmin ? 'Block User' : 'Block Permanently'} onCancel={async () => {
              await SheetManager.hide('user_menu_report')
              if (this.context.state.isAdmin) {
                this.context.banUser()
              } else {
                this.context.reportUserAccount(true)
              }

            }} icon={
              <AntDesign name="flag" size={24} color={colors.iconColorWhite} />} style={{ width: '100%' }} onAccept={() => {
                SheetManager.hide('user_menu_report')
                this.context.reportUserAccount();
              }} message={"Report user for inappropriate behaviour"} acceptLabel={"Report User"} />
          </View>

        </ActionSheet>

        {Platform.OS !== 'web' || 1 === 1 ? <ActionSheet keyboardShouldPersistTaps={'handled'} onBeforeShow={() => {

        }} closeOnTouchBackdrop={!this.context.state.isGeneratingLyrics} containerStyle={{
          maxWidth: colors.maxWidth,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="create_full_song_post">
          <View pointerEvents={this.context.state.isGeneratingLyrics ? 'none' : 'auto'} style={[style.fullWidth, { padding: 20 }]}>
            <Text style={{ fontSize: 18, marginBottom: 10, fontFamily: 'Jost', color: colors.neutralBlue }}>Lyrics description</Text>
            <TextInput autoCapitalize={'sentences'} value={this.context.state.textDescription || ''} autoCorrect={true} blurOnSubmit={true} maxLength={250}
              enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
                //this.loadMagicText();
              }} placeholder={env.defaultLyrics} multiline={true} numberOfLines={2} onChangeText={(text: string) => {
                this.context.setAppProp({ textDescription: text })
              }} style={[style.fullWidth, style.textInput, {
                textAlignVertical: 'center',
                height: 80,
                fontFamily: 'Jost',
                marginBottom: 15,
                color: colors.neutralBlue
              }]}></TextInput>
            <Text style={{ fontSize: 18, marginBottom: 10, fontFamily: 'Jost', color: colors.neutralBlue }}>Song description</Text>
            <TextInput autoCapitalize={'sentences'} value={this.context.state.songDescription || ''} autoCorrect={true} blurOnSubmit={true} maxLength={250}
              enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
                this.loadMagicSong();
              }} placeholder={env.defaultSongStyle} multiline={true} numberOfLines={2} onChangeText={(text: string) => {
                this.context.setAppProp({ songDescription: text })
              }} style={[style.fullWidth, style.textInput, {
                textAlignVertical: 'center',
                height: 80,
                fontFamily: 'Jost',
                marginBottom: 15,
                color: colors.neutralBlue
              }]}></TextInput>


            <PrimaryButton isGold={true} progress={true} circleTime={this.context.state.isExplicit ? 140 : 240} align={'left'} isBusy={this.context.state.isGeneratingLyrics} onPress={this.loadMagicSong.bind(this)} icon={
              <SimpleLineIcons style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='magic-wand'></SimpleLineIcons>} label={'Get Song'} style={{
                width: '100%',
                marginBottom: 0,
                marginTop: 10
              }} />


          </View>
        </ActionSheet> : null}
        {Platform.OS !== 'web' || 1 === 1 ? <ActionSheet keyboardShouldPersistTaps={'handled'} onBeforeShow={() => {

        }} closeOnTouchBackdrop={!this.context.state.isGeneratingLyrics} containerStyle={{
          maxWidth: colors.maxWidth,

          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="create_post">
          <View pointerEvents={this.context.state.isGeneratingLyrics ? 'none' : 'auto'} style={[style.fullWidth, { padding: 20 }]}>
            <Text style={{ fontSize: 18, marginBottom: 10, fontFamily: 'Jost', color: colors.neutralBlue }}>Write the song lyrics about...</Text>
            <TextInput autoCapitalize={'sentences'} value={this.context.state.textDescription || ''} autoCorrect={true} blurOnSubmit={true} maxLength={250}
              enablesReturnKeyAutomatically={true} onSubmitEditing={() => {

                this.loadMagicText();
              }} placeholder='A cat named Tom and a clever mouse named Jerry as they try to outwit each other' multiline={true} numberOfLines={2} onChangeText={(text: string) => {
                this.context.setAppProp({ textDescription: text })
              }} style={[style.fullWidth, style.textInput, {
                textAlignVertical: 'center',
                height: 80,
                fontFamily: 'Jost',
                marginBottom: 15,
                color: colors.neutralBlue
              }]}></TextInput>
            <View></View>
            {this.context.state.adData?.showExplicit && (this.context.state.generatedLyricsNumber || 0) >= this.context.state.adData?.explicitMax ? <View style={[style.row, style.vcenter, { marginBottom: 10 }]}>
              <Switch
                trackColor={{ false: colors.pinkish, true: colors.pinkish }}
                thumbColor={colors.greaysh}
                ios_backgroundColor={colors.pinkish}
                onValueChange={async (value) => {

                  if (this.context.state.isPremiumSubscriber) {
                    this.context.setAppProp({ isExplicit: value })
                    if (value) {
                      this.context.logEvent('explicit_lyrics')
                    }
                  } else {
                    await SheetManager.hide('create_post')
                    setTimeout(() => {
                      SheetManager.show('go_premium_sub')
                    }, 500)
                  }

                }}
                value={this.context.state.isExplicit}
              />
              <Text style={{ marginLeft: 5, fontSize: 17, fontFamily: 'Jost' }}>Explicit Lyrics</Text>
            </View> : null}
            <DoubleButtonBar selectedBtn={(index: number) => {
              this.setState({ selectedLyricsTab: index })
            }} textStyle={{
              color: colors.textColor,
              fontSize: 18,
              fontFamily: 'Jost'
            }} textColor={colors.textColor} style={{ width: '100%', marginBottom: 10 }} initial={this.state.selectedLyricsTab} backgroundColor={colors.purpleish} activeColor={colors.blueish} data={["Style", "Tone"]}></DoubleButtonBar>
            {this.state.selectedLyricsTab === 0 ? <View style={[style.column, style.fullWidth, { height: 250 }]}>

              <Text style={{ fontSize: 18, marginBottom: 10, color: colors.neutralBlue }}>In the style of</Text>

              <ScrollView style={[style.column, { flex: 1, overflow: 'scroll' }]}>

                <RadioGroup key="style" activeColor={colors.blueish} style={{
                  width: '100%',
                  marginBottom: 10
                }} textColor={colors.textColor} initial={this.context.state.appConfig.styles?.indexOf(this.context.state.lyricsStyle)} textStyle={{
                  color: colors.textColor,
                  fontSize: 18,
                  fontFamily: 'Jost'
                }}
                  data={this.context.state.appConfig.styles?.map((item: any) => {
                    return {
                      label: item,
                      type: item
                    }
                  })}
                  selectedBtn={(e: any) => {
                    console.log("e", e)
                    if (e) this.context.setAppProp({ lyricsStyle: e.type })
                  }}
                />
              </ScrollView>
              <TextInput autoCapitalize={'sentences'} autoCorrect={true} maxLength={100} value={this.context.state.appConfig.styles?.indexOf(this.context.state.lyricsStyle) === -1 ? this.context.state.lyricsStyle : ''}
                blurOnSubmit={true} enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
                  this.loadMagicText();
                }} placeholder='Custom style' multiline={false} numberOfLines={1} onChangeText={(text: string) => {
                  this.context.setAppProp({ lyricsStyle: text })
                }} style={[style.fullWidth, style.textInput, {
                  height: 60,
                  fontFamily: 'Jost',
                  color: colors.neutralBlue,
                  textAlignVertical: 'center',
                  marginTop: 15,
                  marginBottom: 15,

                }]}></TextInput>
            </View> : <View style={[style.column, style.fullWidth, { height: 250 }]}>

              <Text style={{ fontSize: 18, marginBottom: 10, color: colors.neutralBlue }}>With the tone</Text>
              <ScrollView style={[style.column, { flex: 1, overflow: 'scroll' }]}>
                <RadioGroup key="tone" activeColor={colors.blueish} style={{
                  width: '100%',
                  marginBottom: 10
                }} textColor={colors.textColor} initial={this.context.state.appConfig.tones?.indexOf(this.context.state.lyricsTone)} textStyle={{
                  color: colors.textColor,
                  fontSize: 18,
                  fontFamily: 'Jost'
                }}
                  data={this.context.state.appConfig.tones?.map((item: any) => {
                    return {
                      label: item + " " + this.context.getEmojy(item),
                      type: item
                    }
                  })}
                  selectedBtn={(e: any) => {
                    console.log("e", e)
                    if (e) this.context.setAppProp({ lyricsTone: e.type })
                  }}
                />
              </ScrollView>
            </View>}


            {this.context.state.appConfig?.allowSubscription ? <View style={[style.row, style.vcenter, { marginBottom: 5 }]}>
              <Switch
                trackColor={{ false: colors.pinkish, true: colors.pinkish }}
                thumbColor={colors.greaysh}
                ios_backgroundColor={colors.pinkish}
                onValueChange={async (value) => {

                  if (this.context.state.isPremiumSubscriber) {
                    this.context.setAppProp({ hasChorus: value })
                  } else {
                    await SheetManager.hide('create_post')
                    setTimeout(() => {
                      SheetManager.show('go_premium_sub')
                    }, 500)
                  }

                }}
                value={this.context.state.hasChorus}
              />
              <Text style={{ marginLeft: 5, fontSize: 17, fontFamily: 'Jost' }}>Include chorus</Text>
              <TouchableOpacity style={{ marginLeft: 5 }} onPress={async () => {
                if (this.context.state.isPremiumSubscriber) {
                  let numVerses = Math.max(((this.context.state.numVerses || 0) - 1), 2);
                  this.context.setAppProp({ numVerses })
                } else {
                  await SheetManager.hide('create_post')
                  setTimeout(() => {
                    SheetManager.show('go_premium_sub')
                  }, 500)
                }
              }}>
                <View style={[style.row, style.hcenter, style.vcenter, { width: 30, height: 30, borderRadius: 15, backgroundColor: colors.pinkish }]}>
                  <Text style={{ color: colors.greaysh, fontSize: 17, fontWeight: 'bold' }}>-</Text>
                </View>
              </TouchableOpacity>
              <Text style={{ marginLeft: 5, fontSize: 17, fontFamily: 'Jost' }}>{this.context.state.numVerses} verses</Text>
              <TouchableOpacity style={{ marginLeft: 5 }} onPress={async () => {
                if (this.context.state.isPremiumSubscriber) {
                  let numVerses = Math.min(((this.context.state.numVerses || 0) + 1), 6);
                  this.context.setAppProp({ numVerses })
                } else {
                  await SheetManager.hide('create_post')
                  setTimeout(() => {
                    SheetManager.show('go_premium_sub')
                  }, 500)
                }
              }}>

                <View style={[style.row, style.hcenter, style.vcenter, { width: 30, height: 30, borderRadius: 15, backgroundColor: colors.pinkish }]}>
                  <Text style={{ color: colors.greaysh, fontSize: 17, fontWeight: 'bold' }}>+</Text>
                </View>
              </TouchableOpacity>
            </View> : null}

            <PrimaryButton progress={true} circleTime={this.context.state.isExplicit ? 140 : 30} align={'left'} isBusy={this.context.state.isGeneratingLyrics} onPress={this.loadMagicText.bind(this)} icon={
              <SimpleLineIcons style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='magic-wand'></SimpleLineIcons>} label={'Get Lyrics'} style={{
                width: '100%',
                marginBottom: 0,
                marginTop: 10
              }} />


          </View>

        </ActionSheet> : null}
        {this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false' && Platform.OS !== 'web' ?
          <Modal
            animationType="fade" style={{
              display: "flex",
              bottom: 0,
              top: 0,
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}

            transparent={true}
            visible={this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false'}

          >
            <View style={[style.fullWidth, style.column, {
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: 0,
            }]}>

              <View style={[{
                height: '100%',
                width: '100%',
                maxWidth: colors.maxWidth,
                padding: 20,
                borderRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingTop: 0,
                backgroundColor: "#fff"
              }]}>
                <ScrollView style={[style.fullWidth, { padding: 20, flex: 1 }]}>
                  <Text style={[style.fullSize, { color: colors.textColor }]}>
                    {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n') + '\n\n'}
                    {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
                  </Text>
                </ScrollView>
                <PrimaryButton align={'left'} onPress={this.agreePolicy.bind(this)}
                  label={'Accept and Continue'} style={{ width: '100%', marginTop: 10 }} />
              </View>

            </View>
          </Modal> : null}
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="feedback_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <TextInput placeholder='Feedback subject' multiline={true} numberOfLines={1} onChangeText={(text: string) => {
              this.setState({ feedbackSubject: text });
            }} style={[style.fullWidth, style.textInput, {

              marginBottom: 15,

            }]}></TextInput>
            <TextInput placeholder='Feedback message' multiline={true} numberOfLines={3} onChangeText={(text: string) => {

              this.setState({ feedbackMessage: text });
            }} style={[style.fullWidth, style.textInput, {
              height: 100,
              marginBottom: 15,

            }]}></TextInput>
            <PrimaryButton isBusy={this.state.isBusyFeedback} align={'left'} onPress={() => {
              this.sendFeedbackMessage();
            }} icon={
              <FontAwesome style={{}} size={20} color={colors.iconColorWhite} name='send'></FontAwesome>} label={'Send'} style={{
                width: '100%',

              }} />
          </View>

        </ActionSheet>
        <ActionSheet keyboardShouldPersistTaps={'handled'} onClose={() => {

        }} containerStyle={{
          zIndex: 9999,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius,
          maxWidth: colors.maxWidth
        }} elevation={3333} defaultOverlayOpacity={0.5} id={`notifications_sheet`}>

          <View style={[style.fullWidth, style.column, {
            height: 400,
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0)',
            padding: 0,
          }]}>
            <View style={[{
              height: '100%',
              width: '100%',
              maxWidth: colors.maxWidth,
              padding: 20,
              borderRadius: 10,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              paddingTop: 0,
              backgroundColor: "#fff"
            }]}>
              <ItemsList ref={ref => {
              }} cache={false} disableBottomScroll={true} style={{ marginTop: 10 }} renderItem={notificationRenderItem} disableRefresh={false} stateKey={'notificationsList'} fn={this.getNotifications.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.notificationsList}></ItemsList>
            </View>

          </View>
        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="admin_message_sheet">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Text style={[style.fullWidth, { color: colors.textColor, padding: 0 }]}>
              {this.context.state.adminMessage}
            </Text>
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="user_menu">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <View style={[style.row, style.vcenter, style.fullWidth, { justifyContent: 'flex-start', marginBottom: 20, marginLeft: 10 }]}>
              <UserButtonLong style={{ width: '100%' }} hideDetails={false} user={this.context.state.selectedUser} ></UserButtonLong>
            </View>
            <IconMenu data={this.selectedUserMenu} style={{ width: '100%' }} onPress={(item: any) => {
              this.onSelectedUserMenuPress(item)
            }
            }></IconMenu>

          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`user_my_menu`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20, paddingTop: 10 }]}>
            <IconMenu data={this.myUserMenu} style={{ width: '100%' }} onPress={(item: any) => {
              this.gotoMyScreen(item)
            }
            }></IconMenu>
            <PrimaryButton align={'right'} onPress={() => {
              this.signOut()
            }} icon={
              <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                width: '100%',
                marginTop: 10, marginBottom: 10
              }} />
          </View>
        </ActionSheet>
        <CommentsModal post={this.context.state.viewPostComments}></CommentsModal>
        {this.context.state.imagePreview ? <FullScreen style={{ position: 'absolute', height: '100%', width: '100%', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }}> </FullScreen> : null}
      </NavigationContainer>
    )
  }
}

export default AppNavigator;
