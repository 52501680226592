import * as React from 'react';
import {
    ActivityIndicator,
    FlatList,
    Switch,
    Image,
    ImageBackground,
    Modal,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput, TouchableOpacity,
    View,
    Clipboard,
    Keyboard,
    StatusBar,
    Dimensions
} from 'react-native';
const AWS = require('aws-sdk');
import AsyncStorage from "@react-native-async-storage/async-storage";
import Lambda from 'aws-sdk/clients/lambda';
import env from "../constants/env";
import { Buffer } from 'buffer';
import Constants from 'expo-constants';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import PrimaryButton from "../components/primary-button";
import { AntDesign, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import Sound from "react-native-sound";
import ButtonBar from "../components/button-bar";
import { LyriPost } from "../models";
import navigationService from "../services/navigation-service";
import ViewShot, { captureRef } from "react-native-view-shot";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { createLyriPost, createUser, updateLyriPost } from "../graphql/mutations";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";

///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import * as queries from "../graphql/queries";
import LyricsPlayer from "../components/lyrics-player";

import GradientText from '../components/gradient-text';
import RadioGroup from '../components/radio-group';
import DoubleButtonBar from '../components/double-button-bar';
import Alert from '../components/alert';
import { BannerAd, BannerAdSize } from 'react-native-google-mobile-ads';


const categories = ["hip hop", "pop", "rock", "electronic", "funk", "jazz", "folk", "dramatic", "motivational", "energetic", "happy", "sad", "romantic", "dark", "party", "relax", "inspiring"];
interface Props {

}

interface State {
    isBusyRecaptureScreenshot?: boolean
    isBusyDownloadImage?: boolean
    isBusyDownloadVideo?: boolean
    isBusySavingPhoto?: boolean
    imageType?: string
    isEditing: boolean
    isSavingLocally: boolean
    opacity?: any
    isDone: boolean
    replaceText?: string
    isNeural: boolean
    isDownloading: boolean
    selectedCategory?: string
    uiView?: 'voice' | 'soundtrack';
    isMyPost?: boolean
    isBusyCategory?: boolean
    images?: any[]
    soundtrackCategories?: any[]
    magicText?: string
    textDescription?: string
    picHeight?: any
    showImageModal?: boolean
    tuneUrl?: string
    shareType?: string
    selectedSoundtrack?: string
    selectedVoice?: string
    backgroundImage?: string
    post?: LyriPost
    isBusy?: boolean;
    isBusyImage?: boolean;
    isBusyImageLoad?: boolean;
    isBusyScreenshot?: boolean;
    isPlaying?: boolean;
}

class FullPostScreen extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext
    interAd: any;
    capture: any
    captureAll: any
    soundtrack: any
    hasReplaceText: boolean = false;
    soundtracks: any[] = []
    isCustomPhoto: boolean = false;
    voices: any[] = []
    premiumVoices: any[] = []
    postSong: any
    voiceLyrics: any
    editedText: any
    private searchTerm: string = "";

    constructor(props: any) {
        super(props);
        this.state = {
            imageType: 'Artwork',
            isDone: false,
            opacity: 1,
            shareType: 'app',
            isSavingLocally: false,
            replaceText: undefined,
            isEditing: false,
            isDownloading: false,
            isMyPost: true,
            isNeural: false,
            uiView: 'voice',
            isBusyScreenshot: false,
            isBusyImageLoad: false,
            picHeight: 110,
            isBusy: false
        }
    }

    async componentDidMount() {
        this.setState({ post: null })





        //if (this.context.state.appConfig.allowSubscription) {
        // this.voices.splice(1, 0, ...this.premiumVoices);
        //}


        setTimeout(async () => {
            let postId = this.context.state.routeParams?.id;
            if (postId) {
                this.setState({ isMyPost: true })
                const post: LyriPost = (await API.graphql({
                    query: queries.getLyriPost,
                    variables: { id: postId }
                }) as any).data.getLyriPost;
                console.log("post", post);
                const backgroundImage = post.background ? await Storage.get(post.background, { level: 'public' }) : undefined;
                this.setState({ post, backgroundImage, textDescription: post.title, isDone: true, isDownloading: false, magicText: post.lyrics });
                // console.log("post", post);
                this.playTrack();
            } else {
                this.createPostAndScrenshot()

            }

        }, 100);

    }
    wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    saveLocally = async (isImage?: boolean, force?: boolean) => {
        this.context.playSound("", "");
        if (isImage) {
            if (this.context.state.isPremium || force) {
                this.setState({ isBusy: true, isDownloading: true, isBusyDownloadImage: true }, async () => {

                    setTimeout(async () => {
                        const uri = await captureRef(this.captureAll, {
                            format: 'png',
                            quality: 1,
                        });
                        await navigationService.props.CameraRoll.save(uri, { type: 'photo' })
                        this.setState({ isBusy: false, isDownloading: false, isBusyDownloadImage: false })
                        this.context.showToast('Image saved to device!', 'success');
                        this.context.logEvent('save_to_device_image');
                        await SheetManager.hide('download_locally');
                    }, 1000)


                })
            } else {
                await SheetManager.hide('download_locally');
                await this.wait(300);
                await SheetManager.show(`go_ad_download`);
            }

        } else {
            if (this.context.state.isPremium) {
                if (this.state.post?.postSong) {
                    this.setState({ isBusy: true, isBusyDownloadVideo: true }, async () => {
                        const credentials = await Auth.currentCredentials()
                        AWS.config.update({
                            region: 'us-east-1',
                            accessKeyId: credentials.accessKeyId,
                            secretAccessKey: credentials.secretAccessKey
                        });
                        const lambda = new Lambda({
                            credentials: Auth.essentialCredentials(credentials),
                            httpOptions: {
                                timeout: 15 * 60 * 1000
                            }
                        });
                        const payload = {
                            imageUrl: this.state.post?.background || "",
                            titleText: this.state.post?.title || "",
                            lyricsText: this.state.post?.lyrics || "",
                            postSong: this.state.post?.postSong || "",
                            isFullSong: true,
                            soundtrack: ""
                        }
                        console.log("payload", payload);
                        lambda.invoke({
                            FunctionName: 'lyricstovideo-prod',
                            Payload: JSON.stringify({
                                body: payload
                            }),
                        }, async (err, data: any) => {
                            //JSON.parse(data.Payload).body.
                            console.log("dta", data);
                            console.log("err", err);
                            const pld = JSON.parse(data.Payload);
                            if (!err && pld.statusCode != 500) {
                                if (pld.body) {
                                    const body = JSON.parse(pld.body);
                                    const remoteUrl = body.videoUrl;
                                    console.log("remoteUrl", remoteUrl);
                                    const localPath = `${navigationService.props.RNFS.DocumentDirectoryPath}/LyriTunesVideo.mp4`;
                                    await navigationService.props.RNFS.downloadFile({ fromUrl: remoteUrl, toFile: localPath }).promise;
                                    //await CameraModule?.saveToCameraRoll(uri, 'photo')
                                    try {
                                        await navigationService.props.CameraRoll.save(localPath, { type: 'video' });
                                        console.log('Video saved successfully');
                                    } catch (error) {
                                        console.error('Failed to save video to camera roll:', error);
                                    }
                                    this.context.showToast('Video saved to device!', 'success');
                                    this.context.logEvent('save_to_device_video');
                                    await SheetManager.hide('download_locally');
                                    this.setState({ isBusy: false, isDownloading: false, isBusyDownloadVideo: false })
                                } else {
                                    this.context.showToast('Something went wrong!', 'error');
                                    this.setState({ isBusy: false, isDownloading: false, isBusyDownloadVideo: false })
                                    await SheetManager.hide('download_locally');
                                }
                            } else {
                                const pld = JSON.parse(data.Payload);
                                if (pld.body) {
                                    console.log("dta", JSON.parse(data.Payload).body);
                                    this.context.showToast('Something went wrong!', 'error');
                                    this.setState({ isBusy: false, isDownloading: false, isBusyDownloadVideo: false })
                                    await SheetManager.hide('download_locally');
                                }
                            }
                        });




                    })

                } else {
                    await SheetManager.hide('download_locally')
                    this.context.showToast('Please select a soundtrack first!', 'error');
                }
            } else {
                await SheetManager.hide('download_locally')
                await this.wait(300);
                SheetManager.show(`go_premium_sub`);
            }
        }

    }
    base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });
        return blob;
    }
    saveScreenshot = async () => {
        const id = `shots/${uuidv4()}.png`;
        const smallUri = await captureRef(this.capture, {
            format: "png",
            quality: 1
        });

        console.log("2")
        let photo;
        let photoBlob;
        let photoBlobData;
        let filename = "";
        try {
            //photo = await fetch(smallUri)
            photoBlobData = await navigationService.props.RNFS.readFile(smallUri, 'base64')
            photoBlob = Buffer.from(photoBlobData, 'base64');
            //await photo.blob();
            filename = id;
            await Storage.put(filename, photoBlob, {
                level: 'public',
                contentType: 'image/png'
            })
        } catch (e) {
            console.log("e", e);
        }
        return id;
    }
    donwloadImage = async () => {
        const imageUrl = this.context.state.fullSong?.image_file;
        if (!imageUrl) return undefined;
        let photoBlob;
        const response = await fetch(imageUrl);
        const arrayBuffer = await response.arrayBuffer();
        const s3Key = `${uuidv4()}.png`;
        try {
            await Storage.put(s3Key, Buffer.from(arrayBuffer), {
                level: 'public',
                contentType: 'image/png',
            });
            return s3Key;

        } catch (e) {
            console.error("Error uploading to S3:", e);
            return undefined;
        }

    }
    getPostSong = async () => {
        const audioUrl = this.context.state.fullSong?.audio_file;
        if (!audioUrl) return undefined;

        const response = await fetch(audioUrl);
        const audioBuffer = await response.arrayBuffer();

        const s3Key = `tunes/${uuidv4()}.mp3`;

        try {
            await Storage.put(s3Key, Buffer.from(audioBuffer), {
                level: 'public',
                contentType: 'audio/mp3',
            });
            return s3Key;
        } catch (error) {
            console.error("Error uploading to S3:", error);
            return undefined;
        }
    }
    createPostAndScrenshot = async () => {
        if (this.state.post) return;
        /* console.log("magicText", this.context.state.magicText);
        console.log("lyrics", this.context.state.lyrics); */
        this.setState({ isBusyScreenshot: true, opacity: 1 }, async () => {
            setTimeout(async () => {
                console.log("1")

                const [bgId, psid] = await Promise.all([this.donwloadImage(), this.getPostSong()])

                try {
                    console.log("4")
                    let p: any = {
                        background: bgId,
                        lyrics: this.context.state.magicText,
                        style: this.context.state.songDescription,
                        type: 'private',
                        isFullSong: true,
                        isExplicit: false,
                        backgroundPremium: true,
                        isGIF: false,
                        postSong: psid,
                        reports: [],
                        title: this.context.state.textDescription || env.defaultLyrics,
                        numLikes: 0,
                        savedAt: new Date().toISOString()
                    }
                    if (this.context.state.user) {
                        p.userId = this.context.state.user.id;
                    }
                    const [backgroundImage, postSong] = await Promise.all([Storage.get(bgId || "", { level: 'public' }), Storage.get((psid || "").replace('public/', ''), { level: 'public' })])
                    console.log("backgroundImage", backgroundImage)
                    console.log("postSong", postSong)
                    this.postSong = postSong;
                    let post = (await API.graphql(graphqlOperation(createLyriPost, { input: p })) as any).data.createLyriPost;
                    this.setState({ shareType: this.context.state.isExplicit ? 'social' : 'app', opacity: 0, backgroundImage: backgroundImage, isMyPost: false, post: post, isDownloading: false, picHeight: undefined });
                    await this.wait(2000);
                    const screenshot = await this.saveScreenshot();
                    post = (await API.graphql(graphqlOperation(updateLyriPost, {
                        input: {
                            id: post.id,
                            screenshot
                        }
                    })) as any).data.updateLyriPost;
                    this.setState({ post: post, isBusyScreenshot: false, isDone: true })
                    const generatedLyricsNumber = parseInt(await AsyncStorage.getItem("generatedLyricsNumber") || "0") || 0;
                    const hasReview = await AsyncStorage.getItem(this.context.state.appConfig.reviewKey || "hasReview");
                    if (!hasReview && generatedLyricsNumber >= (this.context.state.appConfig.reviewAfterLyricsNumber || 5)) {
                        AsyncStorage.setItem(this.context.state.appConfig.reviewKey || "hasReview", "true");
                        SheetManager.show('app_review');
                    }
                    console.log("6")
                    const hasLikePremium = await AsyncStorage.getItem("hasLikePremium");
                    if (!hasLikePremium && generatedLyricsNumber >= 3 && !this.context.state.isPremium) {
                        AsyncStorage.setItem("hasLikePremium", "true");
                        navigationService.props.Alert.alert(
                            'Premium 😀',
                            `Get ${this.context.state.appConfig.numLikesPremium || 100} total likes ❤️ and receive a free premium subscription for life 🎉 `,
                            [

                                {
                                    text: 'Got it',
                                    onPress: () => {
                                    }
                                },
                            ],
                            { cancelable: true })
                    }
                    console.log("7")
                } catch (e: any) {
                    console.log("kakae", e)
                    this.context.showToast('Error upload ' + e.message, 'error');
                }
            }, 1000)
        });

    }

    componentDidUpdate(prevPops: any) {

    }

    componentWillMount() {
        this.setState({ post: null, magicText: "" });
    }

    generateVideo = async () => {
        let generatedTunesString = await AsyncStorage.getItem("generatedTunes") || "0";
        let generatedTunesNumber = parseInt(generatedTunesString) || 0;
        generatedTunesNumber++;
        await AsyncStorage.setItem("generatedTunes", generatedTunesNumber.toString());
        console.log("INTER", generatedTunesNumber, this.context.state.adData.inBetween, this.context.state.adData.hasInterstitial)
        if (generatedTunesNumber % this.context.state.adData.inBetween === 0 && this.context.state.adData.hasInterstitial && !this.context.state.isPremium) {
            this.setState({ tuneUrl: undefined });
            this.context.playSound("", "");
            console.log("CREATE INTER")
            this.interAd = this.context.createAd("in", null, () => {
                if (this.state.tuneUrl) {
                    this.context.playSound(this.state.selectedSoundtrack || "", this.state.tuneUrl || "")
                }
            });

        } else {
            this.interAd = null;
        }
        this.playSound("");
        this.setState({ isBusy: true })
        try {
            console.log("selectedVoice", this.state.selectedVoice)
            if (!this.voiceLyrics || this.editedText) {
                const result = (await API.post('lyritunesrest', '/rest/generate-video/', {
                    body: {
                        neural: this.state.isNeural && this.context.state.isPremiumSubscriber,
                        lyrics: this.context.state.lyrics,
                        voice: this.state.selectedVoice,
                    }
                }));
                this.voiceLyrics = result
                console.log("voiceLyrics", result)
                this.editedText = false;
            }
            const post = (await API.graphql(graphqlOperation(updateLyriPost, {
                input: {
                    id: this.state.post?.id,
                    isNeural: this.state.isNeural,
                    soundtrackPremium: this.state.selectedSoundtrack && this.state.selectedSoundtrack?.indexOf('soundtracks/') !== -1,
                    backgroundPremium: this.isCustomPhoto || this.state.imageType === 'GIF',
                    soundtrack: this.state.selectedSoundtrack,
                    postSong: this.voiceLyrics.key
                }
            })) as any).data.updateLyriPost;
            console.log("updateLyriPost", post);
            this.setState({ tuneUrl: this.voiceLyrics.url, post: post }, async () => {
                SheetManager.hide('show_audio_options');
                if (!this.interAd) this.context.playSound(this.state.selectedSoundtrack || "", this.state.tuneUrl || "")
            });
        } catch (e) {
            console.log("error", e)
        }
        this.setState({ isBusy: false })

    }

    async playTrack() {
        if (!this.postSong && !this.state.tuneUrl) {
            this.postSong = await Storage.get((this.state.post?.postSong || "").replace('public/', ''), { level: 'public' });
        }
        const soundtrack = Platform.OS === 'web' ? `${this.state.post?.soundtrack || ""}` : `${this.state.post?.soundtrack || ""}`;
        this.context.playSound(soundtrack, this.state.tuneUrl || this.postSong);
    }

    onAction(action: string) {
        switch (action) {
            case "Download":

                SheetManager.show(`download_locally`);
                break;
            case "Background":
                //SheetManager.show(`set_background`);
                this.setState({ showImageModal: true })
                break;
            case "Share":
                SheetManager.show(`show_share_options`);
                break;
            case "Play":
                if (this.context.state.isPlaying) {
                    this.context.playSound("", "");
                } else {
                    this.playTrack();
                }
                break;
        }

    }
    playRewardedAdAndDownload = async () => {
        this.context.playRewardedAdAndGenerate(this.saveLocally.bind(this, true, true))
    }

    setVoice(voice: any) {
        if (!voice) return;
        this.setState({ selectedVoice: voice.label });
        this.playSound(`${voice.type}.mp3`)
        this.voiceLyrics = null;
    }

    setSoundtrack(soundtrack: any) {
        if (!soundtrack) return;
        this.playSound(soundtrack.type)
        this.setState({ selectedSoundtrack: soundtrack.type });
    }

    componentWillUnmount() {
        this.playSound('');
        this.context.playSound('', '');
    }

    playSound = async (sound?: string) => {
        if (this.soundtrack) {
            this.soundtrack.stop();
            this.soundtrack.release();
        }
        if (!sound) return;
        if (sound.indexOf('soundtracks/') !== -1) {
            this.setState({ isBusyCategory: true });
            const snd = await Storage.get(sound.replace('public/', ''), { level: 'public' });
            this.soundtrack = new navigationService.props.Sound(snd, "", (error: any) => {
                if (error) {
                    console.log('failed to load the sound', error);
                    return;
                }
                // soundtrack.setVolume(0.2);
                this.setState({ isBusyCategory: false });
                console.log('duration in seconds: ' + this.soundtrack.getDuration() + 'number of channels: ' + this.soundtrack.getNumberOfChannels());
                this.soundtrack.play((success: any) => {

                });
            });
        } else {

            this.soundtrack = new navigationService.props.Sound(sound, navigationService.props.Sound.MAIN_BUNDLE, (error: any) => {
                if (error) {
                    console.log('failed to load the sound', error);
                    return;
                }
                // soundtrack.setVolume(0.2);
                console.log('duration in seconds: ' + this.soundtrack.getDuration() + 'number of channels: ' + this.soundtrack.getNumberOfChannels());
                this.soundtrack.play((success: any) => {
                });
            });
        }

    }
    showOptions = () => {
        this.context.playSound('', '');
        this.setState({ selectedCategory: undefined, soundtrackCategories: undefined })
        SheetManager.show('show_audio_options');
    }

    setShare(e: any) {
        if (e.type === 'app' && this.state.post?.isExplicit) {
            return;
        }
        this.setState({ shareType: e.type })

    }

    makePublic = async () => {
        if (!this.state.post) return;
        if (this.state.post?.isExplicit) {
            return;
        }
        try {
            const isAlreadyPublic = this.state.post?.type === "public";
            const post = (await API.graphql(graphqlOperation(updateLyriPost, {
                input: {
                    id: this.state.post.id,
                    type: "public"
                }
            })) as any).data.updateLyriPost;
            this.setState({ isBusyScreenshot: false, post: post });
            this.context.showToast('Post is public now', 'success');
            if (this.context.state.user?.id && !isAlreadyPublic) {
                this.context.updateAccount({ userPosts: (this.context.state.user.userPosts || 0) + 1 })
            }
        } catch (e) {

        }
    }

    async shareMe() {

        await SheetManager.hide(`show_share_options`);
        setTimeout(async () => {
            switch (this.state.shareType) {
                case 'app':
                    if (!this.context.state.user) {
                        this.context.showToast("Please login to share on the app's public timeline", 'error');
                        return;
                    }
                    this.makePublic();
                    break;
                case 'social':
                    if (this.state.post) this.context.shareMe(this.state.post);
                    break;
                case 'both':
                    if (this.context.state.user) {
                        this.makePublic();
                    }
                    if (this.state.post) this.context.shareMe(this.state.post);
                    break;
            }
        }, 500);

    }

    async searchImages() {
        this.setState({ isBusyImage: true })
        let images
        if (this.state.imageType === 'Artwork') {
            images = (await API.post('lyritunesrest', '/rest/images/', {
                body: {
                    colors: "",
                    imageType: 'illustration',
                    keywords: [this.searchTerm]
                }
            }))
        } else {
            images = (await API.post('lyritunesrest', '/rest/gifs/', {
                body: {
                    keyword: this.searchTerm
                }
            })).data
        }
        console.log("images", images)
        this.setState({ images: images, isBusyImage: false })
    }
    savePhoto = async (key: string, isCustomPhoto?: boolean) => {
        this.isCustomPhoto = isCustomPhoto || false;
        const backgroundImage = await Storage.get(key, { level: 'public' });
        console.log("backgroundImage", backgroundImage)
        this.setState({ backgroundImage: backgroundImage, isBusySavingPhoto: false, isBusyRecaptureScreenshot: true }, async () => {
            this.setState({ isBusyImage: false, showImageModal: false, isBusyImageLoad: false });
            let post = (await API.graphql(graphqlOperation(updateLyriPost, {
                input: {
                    id: this.state.post.id,
                    backgroundPremium: this.isCustomPhoto || this.state.imageType === 'GIF',
                    isGIF: this.state.imageType === 'GIF',
                    background: key
                }
            })) as any).data.updateLyriPost;
            this.setState({ post: post });
            this.context.showToast('Post background updated', 'success');
            await this.waitNoOfSeconds(1);
            const id = await this.saveScreenshot();
            post = (await API.graphql(graphqlOperation(updateLyriPost, {
                input: {
                    id: this.state.post.id,
                    screenshot: id
                }
            })) as any).data.updateLyriPost;
            this.setState({ post: post, isBusyRecaptureScreenshot: false });

        })


    }
    waitNoOfSeconds = async (seconds: number) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved');
            }, seconds * 1000);
        });
    }
    loadPhotos = async () => {
        if (!this.context.state.isPremiumSubscriber) {
            this.setState({ showImageModal: false })
            setTimeout(() => {
                SheetManager.show('go_premium_sub');
            }, 500)

            return;
        }
        const options = {
            title: 'Select Image',
            maxHeight: 1100,
            maxWidth: 600,
            storageOptions: {
                skipBackup: true,
                path: 'images',
            },
        };

        const result = await navigationService.props.launchImageLibrary({
            maxWidth: 600,
            maxHeight: 1100,
        });
        //save the resulting image to s3
        if (!result.didCancel) {

            this.setState({ isBusyImageLoad: true });
            const photo = await navigationService.props.RNFS.readFile(result.assets?.[0].uri || "", 'base64')
            const photoBlob = Buffer.from(photo, 'base64');
            /*  const photo = await fetch(result.assets?.[0].uri || "")
             const photoBlob = await photo.blob(); */

            const key = `${result.assets?.[0].fileName}`
            const response = await Storage.put(key, photoBlob, {
                contentType: result.assets?.[0].type,
                level: 'public',
                progressCallback: (progress: any) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
            });
            this.savePhoto(key, true);
        }


    }
    takePhoto = async () => {
        if (!this.context.state.isPremiumSubscriber) {
            this.setState({ showImageModal: false })
            setTimeout(() => {
                SheetManager.show('go_premium_sub');
            }, 500)
            return;
        }
        const result = await navigationService.props.launchCamera({
            maxWidth: 600,
            maxHeight: 1100,
            mediaType: 'photo', cameraType: 'back'
        });
        //save the resulting image to s3
        if (!result.didCancel) {

            this.setState({ isBusyImage: true });

            const photo = await navigationService.props.RNFS.readFile(result.assets?.[0].uri || "", 'base64')
            const photoBlob = Buffer.from(photo, 'base64');

            const key = `${result.assets?.[0].fileName}`
            const response = await Storage.put(key, photoBlob, {
                contentType: result.assets?.[0].type,
                level: 'public',
                progressCallback: (progress: any) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
            });
            this.savePhoto(key, true);
        }


    }
    async setPostImage(image: any) {
        console.log("image", image.url);
        this.setState({ showImageModal: false, isBusySavingPhoto: true })

        const fileName = this.state.post.id + '.' + (image.extension || 'gif');
        const response = await fetch(image.url);
        const blob = await response.blob();
        await Storage.put(fileName, blob, {
            contentType: `image/${image.extension || 'gif'}`,
        });
        this.savePhoto(fileName, false);

    }
    async onSelectCategory() {
        this.setState({ isBusyCategory: true })
        const sounds = (await API.graphql(graphqlOperation(queries.listSoundtracks, {
            filter: { category: { eq: this.state.selectedCategory } }, limit: 1000
        })) as any).data.listSoundtracks.items;
        let soundtrackCategories = sounds.map((sound: any) => {
            return { label: sound.title, type: sound.s3Key };
        })
        //make soundtrackCategories unique array by label
        soundtrackCategories = soundtrackCategories.filter((thing: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
                t.label === thing.label
            ))
        )

        console.log("soundtrackCategories", soundtrackCategories);
        this.setState({ soundtrackCategories, isBusyCategory: false })
    }
    loadSoundtrack = async (category: any) => {
        if (!this.context.state.isPremiumSubscriber) {
            await SheetManager.hide('show_audio_options')
            setTimeout(() => {
                SheetManager.show('go_premium_sub')
            }, 500)
            return;
        }
        this.setState({ selectedCategory: category }, this.onSelectCategory.bind(this))
    }
    copyPaste = async () => {
        Clipboard.setString(this.state.replaceText || this.state.post?.lyrics.replace(/\n\n/g, '\n') || "")
        this.context.showToast('Copied lyrics to clipboard', 'success');
    }
    replaceText = async () => {
        const lyricsWords = this.state.replaceText?.toLowerCase().split(' ');
        //check for profanity in lyricsWords by comparing to cw array
        if (this.context.state.appConfig.cw.some((word: any) => (lyricsWords || []).indexOf(word) >= 0)) {
            this.context.showToast('Please remove profanity', 'error');
            return;
        }
        let lyrics = this.state.replaceText?.replace(/\n/g, ' \n\n');

        this.context.setAppProp({ lyrics, magicText: this.state.replaceText }, async () => {
            const post = (await API.graphql(graphqlOperation(updateLyriPost, {
                input: {
                    id: this.state.post.id,
                    lyrics: this.state.replaceText,
                }
            })) as any).data.updateLyriPost;
            this.setState({ post: post });
            this.context.showToast('Lyrics updated', 'success');
        })
        this.editedText = true;
        this.hasReplaceText = true;
    }
    render() {
        let lyricsStyle = this.state.post?.style || this.context.state.songDescription || env.defaultSongStyle;
        let lyricsTone = ""
        /* if (lyricsStyle === "Default") {
          lyricsStyle = "";
        } */
        if (lyricsTone === "Default") {
            lyricsTone = "";
        }
        const renderItem = ({ item }: any) => {
            return <TouchableOpacity style={{ margin: 5, marginBottom: 0 }} onPress={() => {
                this.setPostImage(item)
            }}>
                <Image style={{
                    width: 150,
                    height: 250,
                    borderRadius: colors.borderRadius
                }} resizeMethod={'auto'} source={{ uri: item.url }}></Image>
            </TouchableOpacity>

        }
        const busyIndicator = () => {
            if (this.state.isBusyScreenshot || this.state.isBusySavingPhoto) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }
        const busyIndicatorCategories = () => {
            if (this.state.isBusyCategory) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }
        return (

            <View style={style.page} >
                <View style={[style.container, {
                    paddingTop: 0, paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 0
                }]}>
                    <ScrollView scrollEnabled={!this.state.isBusy} style={[style.fullWidth, { flex: 1 }]}>
                        <ViewShot ref={ref => this.captureAll = ref} style={{
                            width: '100%',
                            flex: 1,
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            alignItems: 'center',
                            backgroundColor: colors.backgroundColor
                        }} >
                            <ImageBackground source={{ uri: this.state.backgroundImage }} imageStyle={{
                                opacity: 0.3,
                                borderRadius: 0
                            }} resizeMethod={'scale'} style={[style.fullSize, { padding: 0, paddingBottom: 0 }]}>
                                <View style={[style.fullWidth, style.column, style.vcenter, {

                                    flex: 1,

                                }]}>
                                    <View style={[{
                                        padding: 15,
                                        width: '100%',
                                        borderRadius: colors.borderRadius,
                                        marginTop: 10
                                    }]}>
                                        <Text style={{
                                            paddingRight: 80,
                                            color: colors.neutralBlue,
                                            fontFamily: 'Jost',
                                            fontSize: 19
                                        }}>{this.state.textDescription || this.context.state.textDescription || this.state.post?.title || env.defaultLyrics}</Text>
                                        {lyricsStyle ? <Text style={{
                                            color: colors.neutralBlue,
                                            fontFamily: 'Jost',
                                            fontSize: 11,
                                            paddingRight: 80

                                        }}>Style: {lyricsStyle} </Text> : null}

                                        {!this.state.isDownloading && this.state.isDone ? <View style={[style.row, style.vcenter, { position: 'absolute', right: 0, marginTop: 0 }]}>
                                            {!this.state.isEditing ? <TouchableOpacity onPress={this.copyPaste.bind(this)} style={[style.row, style.hcenter, { marginRight: 10 }]}>
                                                <View style={[style.row, style.hcenter, style.vcenter, { width: 30, height: 30, borderRadius: 15, backgroundColor: colors.pinkish }]}>
                                                    <Feather name="clipboard" size={12} color={colors.greaysh} />

                                                </View>
                                            </TouchableOpacity> : null}
                                        </View> : null}
                                    </View>

                                    <Text style={[style.fullWidth, {
                                        color: colors.textColor,
                                        fontWeight: 'normal',
                                        fontFamily: 'Jost',
                                        fontSize: 16,
                                        marginTop: 10,
                                        paddingRight: 5,
                                        lineHeight: colors.lineHeight,
                                        marginBottom: 10,
                                        paddingLeft: 15,

                                    }]}>

                                        {this.state.magicText || this.context.state.magicText}
                                    </Text>

                                    {this.state.isDownloading ? <View style={{ justifyContent: 'center', width: '100%', backgroundColor: "#ffffff", flexDirection: 'row', marginTop: 30, alignItems: 'center' }}>
                                        <Image style={[{
                                            height: 125 * 0.2,
                                            width: 421 * 0.2,
                                        }]} source={{ uri: navigationService.props.logo }}></Image>
                                    </View> : null}

                                </View>

                            </ImageBackground>
                        </ViewShot>
                    </ScrollView>
                    <ButtonBar isTransparent={true} onAction={this.onAction.bind(this)} style={{
                        width: '100%',
                        opacity: this.state.isDone ? 1 : 0.5,
                        marginTop: 10,
                        maxWidth: 500
                    }}></ButtonBar>


                    {busyIndicator()}

                    {this.state.isBusyScreenshot || this.state.isBusyRecaptureScreenshot ? <ViewShot style={{
                        width: 400,
                        flex: 1,
                        position: 'absolute',
                        top: 0,
                        right: Dimensions.get('window').width,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        alignItems: 'center',
                        backgroundColor: colors.backgroundColor
                    }} ref={ref => this.capture = ref}>
                        {this.state.backgroundImage ? <ImageBackground source={{ uri: this.state.backgroundImage || undefined }} imageStyle={{
                            opacity: 0.3,
                            resizeMode: 'cover',
                            borderRadius: 0
                        }} resizeMethod={'scale'} style={[style.fullSize, { padding: 0, paddingBottom: 10 }]}>
                            <Text style={[style.fullSize, {
                                color: colors.textColor,
                                paddingTop: 20,
                                paddingBottom: 20,
                                fontWeight: 'normal',
                                fontFamily: 'Jost',
                                fontSize: 20,
                                marginTop: 10,
                                height: 220,
                                paddingLeft: 20,
                                paddingRight: 20
                            }]}>
                                {this.state.magicText || this.context.state.magicText}
                            </Text>

                        </ImageBackground> :
                            <navigationService.props.LinearGradient
                                start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                colors={[
                                    '#e9f8ff',
                                    '#fbe2f0',
                                    '#eff4ff'
                                ]}
                                locations={[0, 0.5, 1]}
                                style={{ height: '100%', width: '100%' }}>

                                <Text style={[style.fullSize, {
                                    color: colors.textColor,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    fontWeight: 'normal',
                                    fontFamily: 'Jost',
                                    fontSize: 20,
                                    marginTop: 10,
                                    height: 220,
                                    paddingLeft: 20,
                                    paddingRight: 20
                                }]}>
                                    {this.state.magicText || this.context.state.magicText}
                                </Text>
                            </navigationService.props.LinearGradient>}
                    </ViewShot> : null}
                    {this.state.isBusyScreenshot || this.state.isBusyRecaptureScreenshot ? <ViewShot style={{
                        width: 400,
                        flex: 1,
                        position: 'absolute',
                        top: 0,
                        right: Dimensions.get('window').width,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        alignItems: 'center',
                        backgroundColor: colors.backgroundColor
                    }} ref={ref => this.capture = ref}>
                        {this.state.backgroundImage ? <ImageBackground source={{ uri: this.state.backgroundImage || undefined }} imageStyle={{
                            opacity: 0.3,
                            borderRadius: 0
                        }} resizeMethod={'scale'} style={[style.fullSize, { padding: 0, paddingBottom: 10 }]}>
                            <Text style={[style.fullSize, {
                                color: colors.textColor,
                                paddingTop: 20,
                                paddingBottom: 20,
                                fontWeight: 'normal',
                                fontFamily: 'Jost',
                                fontSize: 20,
                                marginTop: 10,
                                height: 220,
                                paddingLeft: 20,
                                paddingRight: 20
                            }]}>
                                {this.state.magicText || this.context.state.magicText}
                            </Text>

                        </ImageBackground> :
                            <navigationService.props.LinearGradient
                                start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                colors={[
                                    '#e9f8ff',
                                    '#fbe2f0',
                                    '#eff4ff'
                                ]}
                                locations={[0, 0.5, 1]}
                                style={{ height: '100%', width: '100%' }}>

                                <Text style={[style.fullSize, {
                                    color: colors.textColor,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    fontWeight: 'normal',
                                    fontFamily: 'Jost',
                                    fontSize: 20,
                                    marginTop: 10,
                                    height: 220,
                                    paddingLeft: 20,
                                    paddingRight: 20
                                }]}>
                                    {this.state.magicText || this.context.state.magicText}
                                </Text>
                            </navigationService.props.LinearGradient>}
                    </ViewShot> : null}
                    {this.state.showImageModal ? <Modal
                        animationType="slide" style={{
                            display: "flex",
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}

                        transparent={true}
                        visible={this.state.showImageModal}
                        onRequestClose={() => {
                            this.setState({ showImageModal: false })
                        }}
                    >
                        <View style={[style.fullHeight, style.column, {
                            backgroundColor: colors.iconColorWhite,
                            width: '100%',
                            padding: 10,
                            paddingTop: Platform.OS !== 'android' ? Constants.statusBarHeight : 0,

                        }]}>

                            <View style={[style.fullWidth, style.row, { justifyContent: 'flex-end' }]}>
                                <TouchableOpacity onPress={() => {
                                    this.setState({ showImageModal: false })
                                }} ><View style={[style.row, style.vcenter, style.hcenter, { width: 40, height: 40, borderRadius: 20, backgroundColor: colors.pinkish }]}>
                                        <AntDesign name="close" size={18} color={colors.greaysh} />
                                    </View></TouchableOpacity>
                            </View>
                            {this.context.state.appConfig.allowSubscription ? <View style={[style.row, style.vcenter, style.fullWidth, { marginBottom: 15, justifyContent: 'space-around' }]}>
                                <View style={{ flex: 1 }}></View>
                                <TouchableOpacity onPress={this.loadPhotos.bind(this)} style={[style.row, style.hcenter, { marginRight: 15, marginBottom: 10 }]}>
                                    <View style={[style.row, style.hcenter, style.vcenter, { width: 50, height: 50, borderRadius: 25, borderWidth: 0, backgroundColor: colors.pinkish, borderColor: colors.purpleColor }]}>
                                        <FontAwesome name="folder-open-o" size={24} color={colors.greaysh} />
                                        {this.state.isBusyImageLoad ? <ActivityIndicator size="large" style={{ position: 'absolute' }} color={colors.busyIndicator} /> : null}
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={this.takePhoto.bind(this)} style={[style.row, style.hcenter, { marginBottom: 10 }]}>
                                    <View style={[style.row, style.hcenter, style.vcenter, { width: 50, height: 50, borderRadius: 25, borderWidth: 0, backgroundColor: colors.pinkish, borderColor: colors.purpleColor }]}>
                                        <MaterialIcons name="photo-camera" size={24} color={colors.greaysh} />
                                        {/* {this.state.isBusyImageLoad ? <ActivityIndicator size="large" style={{ position: 'absolute' }} color={colors.busyIndicator} /> : null} */}
                                    </View>
                                </TouchableOpacity>
                                <View style={{ flex: 1 }}></View>
                            </View> : null}
                            <RadioGroup isRow={true} key="imgtype" activeColor={colors.primaryColor} style={{
                                marginTop: 10,
                                marginBottom: 10
                            }} textColor={colors.textColor} initial={this.state.imageType === 'Artwork' ? 0 : 1} textStyle={{
                                color: colors.textColor,
                                fontSize: 18
                            }}
                                data={[
                                    {
                                        label: 'Artwork',
                                        type: 'Artwork'
                                    },
                                    {
                                        label: 'GIF',
                                        type: 'GIF'
                                    }
                                ]}
                                selectedBtn={async (e: any) => {
                                    console.log("e", e)
                                    this.setState({ imageType: e.type })
                                    if (e.type === 'GIF' && !this.context.state.isPremiumSubscriber) {
                                        this.setState({ imageType: 'Artwork', showImageModal: false })
                                        setTimeout(() => {
                                            SheetManager.show('go_premium_sub');
                                        }, 500)

                                    }

                                }}
                            />
                            <TextInput autoCorrect={true} blurOnSubmit={true} maxLength={100}
                                enablesReturnKeyAutomatically={true} onSubmitEditing={() => {

                                    this.searchImages();
                                }} placeholder='Keyword' multiline={true} numberOfLines={1} onChangeText={(text: string) => {
                                    this.searchTerm = text;
                                }} style={[style.fullWidth, style.textInput, {
                                    textAlignVertical: 'center',
                                    marginBottom: 15,

                                }]}></TextInput>
                            <FlatList horizontal={false} numColumns={2} contentContainerStyle={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }} data={this.state.images} style={{
                                width: '100%',
                                flex: 1,
                            }} renderItem={renderItem} keyExtractor={(item) => item.title}
                            />
                            <PrimaryButton progress={false} align={'left'} isBusy={this.state.isBusyImage} onPress={this.searchImages.bind(this)} icon={
                                <FontAwesome name="search" style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} />} label={'Search Image'} style={{
                                    width: '100%',
                                    marginBottom: 10,
                                    marginTop: 10
                                }} />
                        </View>

                    </Modal> : null}
                    <ActionSheet containerStyle={{
                        maxWidth: 500, borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRadius: colors.borderRadius
                    }} defaultOverlayOpacity={0.5} id="download_locally">
                        <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
                            <PrimaryButton progress={true} align={'left'} isBusy={this.state.isBusyDownloadImage} onPress={this.saveLocally.bind(this, true, false)} icon={
                                <Feather name="image" style={{ flexDirection: 'row-reverse', marginTop: 2 }} size={20} color={colors.iconColorWhite} />} label={'Image'} style={{
                                    width: '100%',
                                    marginBottom: 10,
                                    marginTop: 10
                                }} />
                            <PrimaryButton progress={true} align={'left'} circleTime={this.state.post?.background?.indexOf('.gif') !== -1 || this.state.post?.background?.indexOf('.undefined') !== -1 ? 100 : 15} isBusy={this.state.isBusyDownloadVideo} onPress={this.saveLocally.bind(this, false)} icon={
                                <Feather name="video" style={{ flexDirection: 'row-reverse', marginTop: 2 }} size={20} color={colors.iconColorWhite} />} label={'Video'} style={{
                                    width: '100%',
                                    marginBottom: 10,
                                    marginTop: 10
                                }} />
                        </View>

                    </ActionSheet>
                    <ActionSheet containerStyle={{
                        maxWidth: 500, borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRadius: colors.borderRadius
                    }} defaultOverlayOpacity={0.5} id="go_ad_download">
                        <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
                            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
                                await SheetManager.hide('go_ad_download')

                                setTimeout(() => {
                                    SheetManager.show('buy_premium');
                                }, 100);
                            }} onAccept={() => {
                                SheetManager.hide('go_ad_download');
                                this.playRewardedAdAndDownload();

                            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
                        </View>

                    </ActionSheet>
                    <ActionSheet closeOnPressBack={true} onClose={() => {

                    }} keyboardShouldPersistTaps={'handled'} containerStyle={{
                        maxWidth: 400, borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRadius: colors.borderRadius
                    }} defaultOverlayOpacity={0.5} id={`set_background`}>
                        <View style={[style.fullWidth, style.column, style.hcenter, { padding: 20, height: 600 }]}>


                        </View>
                    </ActionSheet>
                    <ActionSheet closeOnPressBack={true} onClose={() => {

                    }} keyboardShouldPersistTaps={'handled'} containerStyle={{
                        maxWidth: 400, borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRadius: colors.borderRadius
                    }} defaultOverlayOpacity={0.5} id={`show_share_options`}>
                        <View style={[style.fullWidth, style.hcenter, { padding: 20 }]}>

                            <RadioGroup activeColor={colors.blueish} style={{
                                width: '100%',
                                marginBottom: 10
                            }} textColor={colors.textColor} initial={this.state.post?.isExplicit ? 1 : 0} textStyle={{
                                color: colors.textColor,
                                fontSize: 18
                            }}
                                data={[{
                                    label: "Share on app's timeline",
                                    type: 'app'
                                }, {
                                    label: "Share on Social Media",
                                    type: 'social'
                                }, {
                                    label: "Share on both",
                                    type: 'both'
                                }]}
                                selectedBtn={(e: any) => {
                                    console.log("e", e)
                                    this.setShare(e)
                                }}
                            />

                            <PrimaryButton progress={true} align={'left'} isBusy={this.state.isBusy} onPress={this.shareMe.bind(this)} icon={
                                <FontAwesome name="share" style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} />} label={'Share'} style={{
                                    width: '100%',
                                    marginBottom: 10,
                                    marginTop: 10
                                }} />
                        </View>
                    </ActionSheet>
                </View>

            </View >

        )
    };

}

export default FullPostScreen

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});
