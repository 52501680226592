import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { User } from "../models";
import { SheetManager } from 'react-native-actions-sheet';

interface State {
  userLevel?: any
}

interface Props {
  hideBorder?: boolean,
  user?: User | null
  isPremium?: boolean
  isPremiumSubscription?: boolean
  style?: ViewStyle
  size: number
  onPress?: any
}

class UserButton extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }
  async onPressMe() {
    if (!this.props.user?.id) return;
    if (this.props.user?.id !== this.context.state.user?.id) {
      this.context.setAppProp({ selectedUser: this.props.user });
      await Promise.all([SheetManager.hide('comments-box'), SheetManager.hide('notifications_sheet'), this.context.waitSomeSeconds(100)]);
      await this.context.waitSomeSeconds(100);
      SheetManager.show(`user_menu`);

    } else {
      SheetManager.show(`user_my_menu`);
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[style.column, style.vcenter, this.props.style, { marginBottom: this.props.user?.isPremium ? 14 : 14 }]}>

        {this.props.user?.isPremium || this.props.user?.isPremiumSubscription || this.props.user?.isPremiumPlatinum ? <MaterialCommunityIcons name="crown" size={14} color={this.props.user?.isPremiumPlatinum ? colors.platinum : colors.bluePrimary} /> : <View style={{ height: 14 }}></View>}
        <TouchableOpacity onPress={async () => {
          this.onPressMe()

        }} style={[style.userButton, style.hright, {
          width: this.props.size,
          height: this.props.size,
          backgroundColor: "#fff",
          borderWidth: 3,
          borderColor: this.props.user?.isPremiumPlatinum ? colors.platinum : (this.props.user?.isPremiumSubscription ? colors.bluePrimary : colors.greaysh),
          borderRadius: this.props.size,
          justifyContent: "center",
          alignItems: 'center'
        }]}>
          {/* <View style={[style.userButton, style.hright, {
          width: this.props.hideBorder ? this.props.size : this.props.size - 6,
          height: this.props.hideBorder ? this.props.size : this.props.size - 6,
          borderRadius: this.props.hideBorder ? this.props.size : this.props.size - 6,
          justifyContent: "center",
          alignItems: 'center'
        }]}>*/}

          {this.props.user?.picture && this.props.user?.picture !== "-" && this.props.user?.picture?.indexOf('https') !== -1 ?
            <Image style={{ resizeMode: 'cover', width: '100%', height: '100%' }} source={{
              uri: this.props.user?.picture
            }}></Image> : this.props.user ? <Text style={{
              fontFamily: 'Jost',
              color: colors.textColor,
            }}>{this.props.user?.firstName?.charAt(0) + this.props.user?.lastName?.charAt(0)}</Text> :
              <FontAwesome style={{ marginBottom: 0 }} size={this.props.size / 2} color={colors.textColor} name='user'></FontAwesome>}
          {/*</View>*/}

        </TouchableOpacity>
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default UserButton;
