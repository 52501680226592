/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      isPremiumPlatinum
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const geRiddleGuessesByCount = /* GraphQL */ `
  query GeRiddleGuessesByCount(
    $riddleGuessType: String!
    $userRiddleGuesses: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geRiddleGuessesByCount(
      riddleGuessType: $riddleGuessType
      userRiddleGuesses: $userRiddleGuesses
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikesByCount = /* GraphQL */ `
  query GetLikesByCount(
    $userLikeType: String!
    $userLikes: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByCount(
      userLikeType: $userLikeType
      userLikes: $userLikes
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByCount = /* GraphQL */ `
  query GetPostsByCount(
    $userPostsType: String!
    $userPosts: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByCount(
      userPostsType: $userPostsType
      userPosts: $userPosts
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSharesByCount = /* GraphQL */ `
  query GetSharesByCount(
    $userSharesType: String!
    $userShares: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSharesByCount(
      userSharesType: $userSharesType
      userShares: $userShares
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnsubcribedEmails = /* GraphQL */ `
  query GetUnsubcribedEmails($id: ID!) {
    getUnsubcribedEmails(id: $id) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUnsubcribedEmails = /* GraphQL */ `
  query ListUnsubcribedEmails(
    $filter: ModelUnsubcribedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnsubcribedEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emails
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLyriPost = /* GraphQL */ `
  query GetLyriPost($id: ID!) {
    getLyriPost(id: $id) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      isFullSong
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLyriPosts = /* GraphQL */ `
  query ListLyriPosts(
    $filter: ModelLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLyriPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByTimeAndUser = /* GraphQL */ `
  query GetPostsByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHoroscopeByDate = /* GraphQL */ `
  query GetHoroscopeByDate(
    $horoscopeDate: String!
    $horoscopeSavedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHoroscopeByDate(
      horoscopeDate: $horoscopeDate
      horoscopeSavedAt: $horoscopeSavedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsBySocial = /* GraphQL */ `
  query GetPostsBySocial(
    $socialType: String!
    $socialSavedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsBySocial(
      socialType: $socialType
      socialSavedAt: $socialSavedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostsByTime = /* GraphQL */ `
  query GetPostsByTime(
    $type: PostType!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopLyriPost = /* GraphQL */ `
  query GetTopLyriPost($id: ID!) {
    getTopLyriPost(id: $id) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopLyriPosts = /* GraphQL */ `
  query ListTopLyriPosts(
    $filter: ModelTopLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopLyriPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lyriPostId
        lyriPost {
          id
          userId
          screenshot
          style
          background
          ipLikes
          tone
          originalNewsLink
          newsDate
          backgroundPremium
          isNeural
          isGIF
          lyrics
          soundtrack
          isFullSong
          soundtrackPremium
          isExplicit
          socialThemeKeyword
          horoscopeSavedAt
          horoscopeDate
          socialType
          socialSavedAt
          postSong
          postVideo
          type
          title
          reports
          numLikes
          numViews
          numComments
          isPTwetter
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopPostsByTime = /* GraphQL */ `
  query GetTopPostsByTime(
    $type: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopLyriPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTopPostsByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lyriPostId
        lyriPost {
          id
          userId
          screenshot
          style
          background
          ipLikes
          tone
          originalNewsLink
          newsDate
          backgroundPremium
          isNeural
          isGIF
          lyrics
          soundtrack
          isFullSong
          soundtrackPremium
          isExplicit
          socialThemeKeyword
          horoscopeSavedAt
          horoscopeDate
          socialType
          socialSavedAt
          postSong
          postVideo
          type
          title
          reports
          numLikes
          numViews
          numComments
          isPTwetter
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRiddlePost = /* GraphQL */ `
  query GetRiddlePost($id: ID!) {
    getRiddlePost(id: $id) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRiddlePosts = /* GraphQL */ `
  query ListRiddlePosts(
    $filter: ModelRiddlePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiddlePosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipAnswers
        correctAnswers
        wrongAnswers
        lyrics
        correctAnswer
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRiddlesByTime = /* GraphQL */ `
  query GetRiddlesByTime(
    $type: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRiddlePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRiddlesByTime(
      type: $type
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAnswers
        correctAnswers
        wrongAnswers
        lyrics
        correctAnswer
        type
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSoundtrack = /* GraphQL */ `
  query GetSoundtrack($id: ID!) {
    getSoundtrack(id: $id) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSoundtracks = /* GraphQL */ `
  query ListSoundtracks(
    $filter: ModelSoundtrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoundtracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        category
        s3Key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoters = /* GraphQL */ `
  query GetPromoters($id: ID!) {
    getPromoters(id: $id) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPromoters = /* GraphQL */ `
  query ListPromoters(
    $filter: ModelPromotersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tiktokId
        avatarUrl
        previewUrl
        videoUrl
        tiktokProfile
        type
        description
        addedTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoterByType = /* GraphQL */ `
  query GetPromoterByType(
    $type: String!
    $addedTime: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromotersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPromoterByType(
      type: $type
      addedTime: $addedTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tiktokId
        avatarUrl
        previewUrl
        videoUrl
        tiktokProfile
        type
        description
        addedTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserPostLikes = /* GraphQL */ `
  query GetUserPostLikes($id: ID!) {
    getUserPostLikes(id: $id) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        isFullSong
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserPostLikes = /* GraphQL */ `
  query ListUserPostLikes(
    $filter: ModelUserPostLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPostLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        post {
          id
          userId
          screenshot
          style
          background
          ipLikes
          tone
          originalNewsLink
          newsDate
          backgroundPremium
          isNeural
          isGIF
          lyrics
          soundtrack
          isFullSong
          soundtrackPremium
          isExplicit
          socialThemeKeyword
          horoscopeSavedAt
          horoscopeDate
          socialType
          socialSavedAt
          postSong
          postVideo
          type
          title
          reports
          numLikes
          numViews
          numComments
          isPTwetter
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLikesByTimeAndUser = /* GraphQL */ `
  query GetLikesByTimeAndUser(
    $userId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPostLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByTimeAndUser(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        post {
          id
          userId
          screenshot
          style
          background
          ipLikes
          tone
          originalNewsLink
          newsDate
          backgroundPremium
          isNeural
          isGIF
          lyrics
          soundtrack
          isFullSong
          soundtrackPremium
          isExplicit
          socialThemeKeyword
          horoscopeSavedAt
          horoscopeDate
          socialType
          socialSavedAt
          postSong
          postVideo
          type
          title
          reports
          numLikes
          numViews
          numComments
          isPTwetter
          savedAt
          createdAt
          updatedAt
          __typename
        }
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLyriComment = /* GraphQL */ `
  query GetLyriComment($id: ID!) {
    getLyriComment(id: $id) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLyriComments = /* GraphQL */ `
  query ListLyriComments(
    $filter: ModelLyriCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLyriComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommentsByTime = /* GraphQL */ `
  query GetCommentsByTime(
    $postId: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByTime(
      postId: $postId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLyriNotification = /* GraphQL */ `
  query GetLyriNotification($id: ID!) {
    getLyriNotification(id: $id) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLyriNotifications = /* GraphQL */ `
  query ListLyriNotifications(
    $filter: ModelLyriNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLyriNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        seen
        notificationCreatedBy
        notificationCreatedByUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        notificationType
        notificationData
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationsByTime = /* GraphQL */ `
  query GetNotificationsByTime(
    $userId: String!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLyriNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByTime(
      userId: $userId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        seen
        notificationCreatedBy
        notificationCreatedByUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        notificationType
        notificationData
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMessageThreads = /* GraphQL */ `
  query GetUserMessageThreads($id: ID!) {
    getUserMessageThreads(id: $id) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserMessageThreads = /* GraphQL */ `
  query ListUserMessageThreads(
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessageThreads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadsBySender = /* GraphQL */ `
  query GetThreadsBySender(
    $senderId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadsBySender(
      senderId: $senderId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadsByRecipient = /* GraphQL */ `
  query GetThreadsByRecipient(
    $recipientId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageThreadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadsByRecipient(
      recipientId: $recipientId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        sender {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        lastMessage
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMessage = /* GraphQL */ `
  query GetUserMessage($id: ID!) {
    getUserMessage(id: $id) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        isPremiumPlatinum
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserMessages = /* GraphQL */ `
  query ListUserMessages(
    $filter: ModelUserMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationId
        threadId
        senderId
        sender {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        message
        imageKey
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagesByThread = /* GraphQL */ `
  query GetMessagesByThread(
    $threadId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesByThread(
      threadId: $threadId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        threadId
        senderId
        sender {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        recipientId
        recipient {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          isPremiumPlatinum
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        message
        imageKey
        savedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
