import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  Dimensions,
  TextInput,
  ScrollView,
  Platform, ImageBackground, Linking,
  Button
} from 'react-native';
import colors from '../constants/colors';

import * as Animatable from 'react-native-animatable';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { API, graphqlOperation, Storage } from "aws-amplify";
import navigationService from "../services/navigation-service";
//@ts-ignore
import UserButton from "./user-button";
import PrimaryButton from "./primary-button";
import { LyriPost, PostType } from "../models";
import LyriItemBar from "./lyri-iitem-bar";
import { Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import LyricsPlayer from "./lyrics-player";
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import {

  updateLyriPost

} from "./../graphql/mutations";
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import Alert from './alert';
import UserButtonLong from './user-button-long';

interface State {
  turnConfetti?: boolean
  lyricsHeight?: number
  backgroundImage?: string
  expanded?: boolean
  finished?: boolean
}

interface Props {
  isCompact?: boolean
  isHome?: boolean
  item: LyriPost,
  index?: number
  hasRewarded?: boolean
  isMyPost?: boolean
  onPress: any
  style?: ViewStyle
}
const fadeInOut = {
  0: {
    opacity: 0.5
  },
  0.5: {
    opacity: 1
  },
  1: {
    opacity: 0.5
  }
};
class LyriItem extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext
  lyricsHeight: number = 0;

  postSong: any
  expended: boolean;
  public player: any;
  private finished: boolean;

  constructor(props: any) {
    super(props);
    this.state = { lyricsHeight: 0 }

  }

  openUserMenu(user: any) {

    this.context.openReportUser(user);
  }

  async componentDidMount() {
    this.finished = true;
    const backgroundImage = this.props.item.background ? await Storage.get(this.props.item.background, { level: 'public' }) : "";
    this.setState({ backgroundImage });

  }
  playRewardedAdAndView = () => {
    this.context.playRewardedAdAndGenerate(this.itemPress.bind(this, true));
  }
  itemPress = async (force?: boolean) => {
    console.log("itemPress", this.props.item, force)
    if (this.props.isCompact) return;
    if (!this.props.isMyPost) {
      if (this.props.hasRewarded && !this.context.state.isPremium && !force) {
        this.context.showRewarded(this.itemPress.bind(this, true))
        return;
      }
      this.context.setAppProp({ viewPost: this.props.item });
      if (this.props.onPress) this.props.onPress();
    } else {
      if (this.props.onPress) this.props.onPress();
    }

  }
  openLinkInBrowser() {
    if (Platform.OS === 'web') {
      (window as any).open(this.props.item.originalNewsLink, '_blank');

    } else {
      InAppBrowser.open(this.props.item.originalNewsLink)
    }
  }

  reportUser() {

  }

  render() {
    const textu = () => {
      return <Text onLayout={(event) => {
        if (!this.lyricsHeight) {
          this.lyricsHeight = event.nativeEvent.layout.height;
          this.setState({ lyricsHeight: event.nativeEvent.layout.height + (this.props.item.originalNewsLink ? 35 : 0) })
        }

      }} style={[style.fullSize, {
        color: colors.textColor,
        fontSize: 15,
        lineHeight: colors.lineHeight,
        paddingLeft: 5,
        paddingRight: 5
      }]}>
        {this.props.item.lyrics}
      </Text>

    }
    return (

      this.props.item ?
        <View style={{ width: '100%', padding: this.props.isCompact ? 0 : 10, paddingTop: 5 }}>

          {!this.context.state.isPremium && (this.props.index || 0) !== 0 && ((this.props.index || 0)) % this.context.state.adData?.banSkip === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?

            <View style={[{
              marginTop: 0,
              backgroundColor: '#fff',
              height: 260,
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 5,
              width: '100%',
            }]}>

              <navigationService.props.BannerAd onAdFailedToLoad={() => {

              }}
                unitId={this.context.state.adData?.ban}
                size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                requestOptions={{
                  requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                }}
              />
            </View> : null}

          <View style={[{
            borderRadius: this.props.item.isTopOfTheWeek ? colors.borderRadius : 0,
            backgroundColor: this.props.item.isTopOfTheWeek ? colors.pinkish : '#fff',
            padding: this.props.item.isTopOfTheWeek ? 10 : 0,
            paddingTop: 0,
            marginBottom: 5,
            width: '100%',
          }]}>

            <View style={[style.fullWidth, {
              borderRadius: colors.borderRadius,
              paddingBottom: this.state.backgroundImage && this.state.expanded ? 10 : 0
            }]}>

              <View style={[this.props.item.isTopOfTheWeek ? style.column : style.row, this.props.item.isTopOfTheWeek ? { alignItems: 'flex-start' } : style.vcenter, {
                marginBottom: 10,
                width: '100%',
                padding: this.props.isCompact ? 0 : 5,
                paddingBottom: 5,
                borderRadius: colors.borderRadius,

              }]}>
                {this.props.item.socialType !== "instanews" && this.props.item.socialType !== "insta" ? (this.props.item.isTopOfTheWeek ? <UserButtonLong isTopOfTheWeek={true} style={{ width: '100%' }} hideDetails={false} user={this.props.item?.user} ></UserButtonLong>


                  : <UserButton hideBorder={true} onPress={this.openUserMenu.bind(this, this.props.item?.user)} user={this.props.item?.user} size={35}></UserButton>) : null}
                <View style={[style.column, style.hcenter, { flex: 1, width: "100%" }]}>
                  <Text numberOfLines={this.state.expanded ? undefined : 2} style={{
                    marginLeft: 5,
                    color: this.props.item.isExplicit && this.context.state.isAdmin ? '#ff0000' : colors.textColor,
                    fontFamily: 'Jost',
                    fontSize: 16,
                    width: '100%',
                    marginTop: 0,
                    marginBottom: 0
                  }}>{this.props.item.title}</Text>
                  <View style={[style.row, style.vcenter, style.fullWidth, {

                    marginTop: 5
                  }]}>
                    <Text style={{
                      marginLeft: 5,
                      flex: 1,
                      color: colors.textColor,
                      fontFamily: 'Jost',
                      fontWeight: 'normal',
                      fontSize: 12
                    }}>Style: {this.props.item?.style || 'Default'} {this.props.item?.tone ? this.context.getEmojy(this.props.item?.tone) : ''}</Text>
                    {this.props.item.background && !this.props.item.isGIF ?
                      <View style={{ display: 'flex', alignContent: 'center', alignItems: 'center', padding: 4, borderRadius: 24, backgroundColor: this.props.item?.isFullSong ? colors.platinum : colors.pinkish }}>
                        <Feather name="image" style={{
                          marginRight: 5,
                          marginLeft: 5
                        }} size={16} color={this.props.item?.isFullSong ? colors.whiteish : (this.props.item.backgroundPremium ? colors.bluePrimary : colors.greaysh)} /></View> : null}
                    {this.props.item.background && this.props.item.isGIF ?
                      <View style={{ display: 'flex', alignContent: 'center', alignItems: 'center', padding: 4, borderRadius: 24, backgroundColor: this.props.item?.isFullSong ? colors.platinum : colors.pinkish }}>
                        <Animatable.View animation={fadeInOut} duration={1000} iterationCount="infinite">
                          <Feather name="image" style={{
                            marginRight: 5,
                            marginLeft: 5
                          }} size={16} color={this.props.item?.isFullSong ? colors.whiteish : colors.yellow} /></Animatable.View></View> : null}
                    {this.props.item.postSong ? <View style={{ padding: 4, marginLeft: 5, display: 'flex', alignContent: 'center', alignItems: 'center', borderRadius: 24, backgroundColor: this.props.item?.isFullSong ? colors.platinum : colors.pinkish }}><MaterialIcons style={{ marginRight: 5, marginLeft: 5 }} name="music-note" size={16} color={this.props.item?.isFullSong ? colors.whiteish : (this.props.item.soundtrackPremium ? colors.bluePrimary : colors.greaysh)} /></View> : null}
                    {this.props.item.isNeural || this.props.item.isFullSong ? <View style={{ padding: 4, marginLeft: 5, display: 'flex', alignContent: 'center', alignItems: 'center', borderRadius: 24, backgroundColor: this.props.item?.isFullSong ? colors.platinum : colors.pinkish }}><MaterialCommunityIcons style={{ marginRight: 5, marginLeft: 5 }} name="waveform" size={16} color={this.props.item?.isFullSong ? colors.whiteish : colors.bluePrimary} /></View> : null}
                  </View>
                </View>

              </View>
              <ImageBackground source={{ uri: this.state.backgroundImage || navigationService.props.back }} imageStyle={{
                opacity: this.state.backgroundImage ? 0.2 : 1,
                borderWidth: this.props.item.isFullSong ? 6 : 0,
                borderColor: this.props.item.isFullSong ? colors.platinum : 'transparent',
                borderRadius: 16
              }} resizeMethod={'resize'} style={[style.fullWidth, { padding: 0 }]}>

                <View style={{
                  width: '100%',
                  overflow: 'hidden',
                  borderWidth: 0,
                  borderColor: '#ccc',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}>
                  <TouchableOpacity style={[style.fullWidth]} onPress={() => {
                    this.itemPress();

                  }}>

                    <Text style={[{
                      color: colors.textColor,
                      fontSize: 15,
                      fontFamily: 'Jost',
                      height: this.props.isCompact ? 90 : 130,
                      overflow: Platform.OS === 'web' ? 'hidden' : 'visible',
                      lineHeight: colors.lineHeight,
                      paddingLeft: 5,
                      paddingRight: 5
                    }]}>
                      {this.props.item.lyrics}
                    </Text>

                  </TouchableOpacity>
                  {this.props.item.originalNewsLink && this.state.expanded && this.state.finished ? <TouchableOpacity onPress={this.openLinkInBrowser.bind(this)} style={{ width: '100%' }}><Text style={{ width: '100%', textAlign: 'center', textDecorationLine: 'underline', textDecorationColor: colors.purpleColor, color: colors.purpleColor }}>Original news</Text></TouchableOpacity> : null}
                  {!this.props.isCompact ? <LyriItemBar hideCopyPaste={true} notPad={true} style={{
                    marginTop: 10

                  }} lyri={this.props.item} /> : null}
                </View>


              </ImageBackground>
            </View>



          </View>
          {this.props.isHome && this.props.index === 0 ? <View style={[style.row, style.fullWidth, style.hcenter, { marginTop: 10, marginBottom: 0 }]}><TouchableOpacity onPress={() => {
            this.context.setScreen('WeekTops', true)
          }} style={[style.row, style.hcenter, style.vcenter, { borderRadius: colors.borderRadius, backgroundColor: colors.bluePrimary, width: 60, padding: 5 }]}><Text style={{
            fontFamily: 'Jost',
            fontWeight: 'bold',
            color: "#ffffff",
            fontSize: 16
          }}>more</Text></TouchableOpacity></View> : null

          }
        </View> : <View></View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default LyriItem;
