

export default {
    defaultLyrics: "Live and let die",
    defaultSongStyle: "Classic Rock, Male voice",
    androidApp: 'https://play.google.com/store/apps/details?id=com.can.genie',
    iOSApp: 'https://apps.apple.com/ro/app/youvi/id1613563464',
    bannerAndroidNative: "ca-app-pub-3900405957506493/9986103408",
    bannerAndroidInterstitial: "ca-app-pub-3900405957506493/6032228135",
    bannerAndroidRewarded: "ca-app-pub-3900405957506493/4620726506",
    bannerIOSRewarded: "ca-app-pub-3900405957506493/5283867641",
    versions: {
        "iOS": "10",
        "Android": "14"
    },


    labels: ["Get more videos like this", "Signing in is available only on the mobile app", "You have no channel subscriptions", "Sign in with your Google account to view the latest on your channel subscriptions", "Trending", "News", "Entertainment", "Comedy", "Music", "Film", "Science&Tech", "Gaming", "Sports", "Education", "Autos", "Pets", "People", "Style", "Search", "Email Address", "Signing in is available only on the mobile app", 'First Name', 'Last Name', 'Password', "No seen videos yet", "Sign Out", "Search or paste link", "Language", "Donate", "Default", "translated clips", "Could not find any subtitles"],

};
